import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { orderBy, pick } from 'lodash'
import { BlockOutlined, CheckCircleOutline } from '@material-ui/icons'

import { useCreate, useNotify, useRedirect, Button } from 'react-admin'
import guardedFormat from '../../../helpers'
import { usePMT, PMTTokenProps } from './PMTAuthorize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
  })
)

function FinalizeButton(props: PMTTokenProps) {
  const notify = useNotify()
  const onSuccess = () => {
    notify('Transaction finalized')
  }
  const [finalize, state] = usePMT({
    ...props,
    onSuccess,
  })
  const { loading = false, loaded = false } = typeof state === 'object' ? state : {}
  return (
    <Button disabled={loading} onClick={() => (typeof finalize === 'function' ? finalize() : null)}>
      <CheckCircleOutline />
    </Button>
  )
}

function DisplayTransactions(props: any) {
  const classes = useStyles()
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="transaction list">
        <TableHead>
          <TableRow>
            <TableCell align="right">Authorization</TableCell>
            <TableCell>Id</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Message</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(props.rows, ['id'], ['desc']).map((row: any) =>
            row.Authorization == true && !row.TransactionRef ? null : (
              <TableRow key={row.id}>
                <TableCell align="right">
                  {row.Authorization == false ? <CheckCircleOutline /> : <BlockOutlined />}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{`${row.Amount * (row.Type == 'Capture' ? 0.01 : -0.01)} ${
                  props.Currency
                }`}</TableCell>
                <TableCell align="right">{row.Message}</TableCell>
                <TableCell align="right">
                  {guardedFormat(row.created_at, 'dd.MM.yyyy HH:mm')}
                </TableCell>
                <TableCell align="right">
                  {row.Authorization == true && row.TransactionRef ? (
                    <FinalizeButton
                      {...{
                        Currency: props.Currency,
                        CustomerNo: props.CustomerNo,
                        TransactionId: props.TransactionId,
                        Token: props.Token,
                        ...pick(row, ['Amount', 'Mandant', 'Type', 'TransactionRef', 'Id']),
                      }}
                    />
                  ) : (
                    'n/a'
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DisplayTransactions
