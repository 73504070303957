import React from 'react'

import { Create, TextInput, SelectInput, BooleanInput, SimpleForm } from 'react-admin'

import Typography from '@material-ui/core/Typography'

export default function PartnerCreate(props: any) {
  return (
    <Create {...props}>
      <SimpleForm>
        <Typography variant="body2" component="div" />
        <TextInput source="name" />
        <TextInput source="email" />
        <TextInput
          source="agenturnummer"
          helperText="Kettenkopf zur statistischen Erfassung (nur sinnvoll wenn mehrere Unteragenturen vorhanden sind)"
        />
        <SelectInput
          source="mandant"
          choices={[
            { id: 'DE', name: 'DE' },
            { id: 'AT', name: 'AT' },
            { id: 'CH', name: 'CH' },
          ]}
        />
        <BooleanInput source="bookings_cancelable" />
      </SimpleForm>
    </Create>
  )
}
