import * as React from 'react'
import { useSelector } from 'react-redux'
import { Layout, LayoutProps, Sidebar } from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'

function CustomSidebar(props: any) {
  return <Sidebar {...props} size={200} />
}

export default function (props: LayoutProps) {
  return <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} />
}
