import * as React from 'react'
import { createStyles, makeStyles, Theme, Typography, TextField, Button } from '@material-ui/core'
import { ReduxState } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { CCState, CC_CORPORATE_UPDATED } from '../../../../custom/ccCorporate'
import CruiselineChooser from '../CruiseSearch/CruiselineChooser'
import { FetchData } from '../SearchResult'
import SyncPage from './Sync/Page'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      '& .MuiFormControl-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    refInput: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
    },
  })
)

function BookingDisplay() {
  const {
    display_booking: { booking } = {},
    sailing,
    environment,
    account,
    cruiseline,
  } = useSelector((state: ReduxState) => (state?.ico || {}) as CCState)
  return sailing.booking_number ? (
    <>
      {booking ? (
        <SyncPage />
      ) : (
        <FetchData
          cmd="CruiseBookingDisplayRQ"
          environment={environment}
          account={account}
          cruiseline={cruiseline}
          payload={[
            {
              booking_number: sailing.booking_number,
            },
          ]}
        />
      )}
    </>
  ) : null
}

function InputBookingRef(props: any) {
  const { booking_number: initial = '', onSubmit } = props
  const classes = useStyles()
  const [booking_number, setBookingNumber] = useState(initial)
  const dispatch = useDispatch()
  const handleClick = () => {
    onSubmit()
    dispatch({ type: CC_CORPORATE_UPDATED, payload: { booking_number } })
  }

  return (
    <div className={classes.refInput}>
      <TextField
        value={booking_number}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setBookingNumber(ev.target.value)}
        label="BookingNumber"
      />
      <Button variant="outlined" onClick={() => handleClick()}>
        check
      </Button>
    </div>
  )
}

export default function Booking() {
  const classes = useStyles()
  const [showInputBookingRef, setShowInputBookingRef] = useState(true)
  const [showCruiselineChooser, setShowCruiselineChooser] = useState(true)
  const {
    display_booking: { booking } = {},
    sailing,
    environment,
    account,
    cruiseline,
  } = useSelector((state: ReduxState) => (state?.ico || {}) as CCState)
  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h1">
        Booking {booking?.booking_number}
      </Typography>
      {showCruiselineChooser ? (
        <>
          <CruiselineChooser />
          <Button onClick={() => setShowCruiselineChooser(false)}>-</Button>
        </>
      ) : (
        <Button onClick={() => setShowCruiselineChooser(true)}>+</Button>
      )}
      {environment && account && cruiseline && showInputBookingRef ? (
        <InputBookingRef
          booking_number={sailing.booking_number}
          onSubmit={() => {
            setShowInputBookingRef(false)
            setShowCruiselineChooser(false)
          }}
        />
      ) : (
        <Button onClick={() => setShowInputBookingRef(true)}>+</Button>
      )}
      <hr />
      <BookingDisplay />
    </div>
  )
}
