import React, { MouseEventHandler, useState, useEffect } from 'react'
import { JSONTree } from 'react-json-tree'

import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  FunctionField,
  ReferenceField,
  useMutation,
  Button,
  ArrayField,
  DateField,
  Datagrid,
  Labeled,
  useDataProvider,
  Loading,
  Error,
  useQueryWithStore,
} from 'react-admin'

import { Box, Card, CardContent, Typography, Button as MUIButton } from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'
import { DateFmtField } from './SaleList'
import AgencyLink from './AgencyLinkField'

function AddToDavinciButton({ id }: { id: string }) {
  const [mutate, { loading, error }] = useMutation()
  const bookingRQ = () =>
    mutate({
      type: 'update',
      resource: 'ibe-sales',
      payload: {
        id,
        data: { addToDavinci: true },
      },
    })
  return error ? (
    <Alert severity="error">{error.message}</Alert>
  ) : (
    <Button label="Book to Davinci" onClick={() => bookingRQ()} disabled={loading} />
  )
}

function GenvasDump({ record }: any) {
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: `${record.mandant}/genvas`,
    payload: { id: record.davinci_id },
  })
  if (!loaded) {
    return <Loading />
  }
  if (error) {
    return <Error error={error} />
  }

  return typeof record !== 'undefined' ? (
    'BookingState' in data ? (
      <JSONTree data={data} />
    ) : (
      AddToDavinciButton(record)
    )
  ) : null
}

function PayloadDump(props: any) {
  return <JSONTree data={props.record ? props.record.value.payload : {}} />
}

function BookingLink({ record = {} }: any) {
  const link =
    record && record.token
      ? `https://www.${record.value.payload.environment.domain}/booking/option/verify/${record.token}/${record.id}`
      : null
  return link ? (
    <Labeled label="Confirmation">
      <div>
        <MUIButton onClick={() => window.open(link)}>Confirm with: {record.value.secret}</MUIButton>
        <p>{link}</p>
      </div>
    </Labeled>
  ) : null
}

export default function (props: any) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <AgencyLink {...props} />
          <TextField source="customer_id" label="Kundennummer" />
          <TextField source="davinci_id" label="Vorgangsnummer" />
          <TextField source="mandant" label="Mandant" />
          <DateFmtField source="created_at" label="Booking Date" />
          <TextField source="cruiseline" label="Cruiseline" />
          <TextField source="channel" label="Booking Type" />
          <TextField source="status_davinci" label="Status davinci" />
          <TextField source="status_docuware" label="Status docuware" />
          <DateFmtField source="option_date" label="Davinci Optionsdatum" />
          <TextField source="value.payload.sale.optionDate" label="IBE Optionsdatum" />
          <TextField
            source="value.payload.sale.optionDateCruiseline"
            label="Optionsdatum Reederei"
          />
          <TextField source="value.payload.sale.bookingNumber" label="Booking" />
          <TextField source="value.payload.cruise.sailingId" label="Sailing ID" />
          <TextField source="value.payload.cruise.sailingDate" label="Sailing Date" />
          <BookingLink {...props} />
        </Tab>
        <Tab label="Log" path="log">
          <ArrayField source="log">
            <Datagrid>
              <TextField source="value" />
              <DateField source="created_at" showTime />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Payload" path="payload">
          <PayloadDump {...props} />
        </Tab>
        <Tab label="Genvas" path="genvas">
          <GenvasDump {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
