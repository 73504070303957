import PropTypes from 'prop-types'
import React from 'react'
import { ReduxState } from 'react-admin'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormControl, Select, MenuItem, InputLabel, Paper, Checkbox } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import {
  CC_CORPORATE_UPDATED,
  CC_CORPORATE_SEARCH_UPDATE,
  CCState,
} from '../../../../custom/ccCorporate'

import ShipChooser from './ShipChooser'
import NumberOfGuestChooser from './NumberOfGuestsChooser'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

function CurrentCheck(props: any) {
  const { label, sailing, value } = props
  const dispatch = useDispatch()

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>, checked: boolean) => {
    dispatch({ type: CC_CORPORATE_UPDATED, payload: { [value]: checked } })
  }

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Checkbox value={sailing[value]} onChange={handleChange} />
    </FormControl>
  )
}

CurrentCheck.propTypes = {
  label: PropTypes.string,
  sailing: PropTypes.object,
  value: PropTypes.string,
}

function CurrentSelect(props: any) {
  const { label, sailing, options, value } = props
  const dispatch = useDispatch()

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: CC_CORPORATE_UPDATED,
      payload: { [value]: ev.target.value as string },
    })
  }

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select value={sailing[value]} onChange={handleChange}>
        {options.map((option: string) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

CurrentSelect.propTypes = {
  label: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.string),
  sailing: PropTypes.any,
  value: PropTypes.any,
}

export default function CruiseSearch() {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { sailing, search, current } = useSelector(
    (state: ReduxState) => (state?.ico || {}) as CCState
  )

  // const start_date: format(new Date(), 'YYYY-MM-DD')
  // const end_date: format(addDays(new Date(), { days: 3}), 'YYYY-MM-DD')

  const handleStartChange = (from: Date | null) => {
    dispatch({ type: CC_CORPORATE_SEARCH_UPDATE, payload: { from } })
  }

  const handleEndChange = (to: Date | null) => {
    dispatch({ type: CC_CORPORATE_SEARCH_UPDATE, payload: { to } })
  }

  return (
    <div className={classes.root}>
      <Paper>
        <NumberOfGuestChooser />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker value={search.from} onChange={handleStartChange} />
          <DatePicker value={search.to} onChange={handleEndChange} />
        </MuiPickersUtilsProvider>
        <ShipChooser />
        <CurrentSelect
          label="sailing id"
          value="sailing_id"
          sailing={sailing}
          options={current.sailings.map((sailing) => sailing.sailing_id)}
        />
        <CurrentSelect label="fares" value="rate_code" sailing={sailing} options={current.rates} />
        <CurrentSelect
          label="categories"
          value="category_code"
          sailing={sailing}
          options={current.categories}
        />
        <CurrentSelect
          label="cabins"
          value="cabin_number"
          sailing={sailing}
          options={current.cabins}
        />
        <CurrentSelect label="dining" value="sitting" sailing={sailing} options={current.dinings} />
        <CurrentCheck label="with flight" value="has_flight" sailing={sailing} />
      </Paper>
    </div>
  )
}
