import * as React from 'react'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { useQuery, useQueryWithStore } from 'react-admin'
import CruiselineChooser from './CruiseSearch/CruiselineChooser'
import CruiseSearch from './CruiseSearch/index'
import SearchResult from './SearchResult'
import Display from './Display/Option'
import DisplayCache from './Display/Cache'
import AddBooking from './Booking/AddBooking'
import BookingList from './CruiseSearch/BookingList'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  })
)

export default function Page() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CruiselineChooser />
        </Grid>
        <Grid item xs={12}>
          <CruiseSearch />
        </Grid>
        <Grid item xs={8}>
          <SearchResult>
            <Display />
          </SearchResult>
        </Grid>
        <Grid item xs={4}>
          <DisplayCache />
          <BookingList />
        </Grid>
        <Grid item xs={8}>
          <AddBooking />
        </Grid>
      </Grid>
    </div>
  )
}
