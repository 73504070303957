import React from 'react'
import { Create, TextInput, SimpleForm, SelectInput } from 'react-admin'
import Typography from '@material-ui/core/Typography'

export default function (props: any) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="agenturnummer" />
        <SelectInput
          label="Mandant"
          source="client"
          choices={[
            { id: 'DE', name: 'DE' },
            { id: 'AT', name: 'AT' },
            { id: 'CH', name: 'CH' },
          ]}
        />
        <Typography variant="body1" component="p">
          Adressdaten, Namen und Email der Agentur wird aus Davinci genommen und im CRM-Eintrag
          überschrieben. Die Validität wird dabei nicht geprüft. Bitte sorgfältig vorher prüfen, ob
          die IDs korrekt sind.
        </Typography>
      </SimpleForm>
    </Create>
  )
}
