import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import {
  Datagrid,
  ArrayField,
  TextField,
  BooleanField,
  DateField,
  Show,
  SimpleShowLayout,
  NumberField,
  ReferenceField,
  FunctionField,
} from 'react-admin'
import { Gewerbeschein, GewerbescheinThumb } from './OnboardingEdit'

function AgencyDetail(props: any) {
  const {
    record: { data },
  } = props
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Street</TableCell>
          <TableCell>Postcode</TableCell>
          <TableCell>City</TableCell>
          <TableCell>Country</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{data.agencyname}</TableCell>
          <TableCell>{data.agencyemail}</TableCell>
          <TableCell>{data.street}</TableCell>
          <TableCell>{data.zipcode}</TableCell>
          <TableCell>{data.city}</TableCell>
          <TableCell>{data.country}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

function CredentialView(props: any) {
  return props.record.credentials.length ? (
    <ArrayField {...props} source="credentials" label="credentials">
      <Datagrid>
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField source="email" />
        <TextField source="login" />
        <TextField source="password" />
      </Datagrid>
    </ArrayField>
  ) : (
    <p>No credentials given</p>
  )
}

export default function (props: any) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <NumberField source="agenturnummer" />
        <NumberField source="assigned_to" />
        <NumberField source="crm_id" />
        <TextField source="state" />
        <TextField source="client" />
        <TextField source="inkasso" />
        <BooleanField source="partner_is_default" />
        <BooleanField source="gewerbeschein" />
        <BooleanField label="Keyaccount" source="can_use_null_payment" />
        <AgencyDetail />
        <DateField source="lastsync_davinci" />
        <DateField source="lastsync_crm" />
        <ReferenceField source="partner_id" reference="partner" link="show">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="overwrite_inkasso" />
        <CredentialView {...props} />
        <GewerbescheinThumb />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <ArrayField source="changes" label="changes">
          <Datagrid>
            <TextField source="text" />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
            <TextField source="user" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}
