import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { ReduxState } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { CCState, CC_CORPORATE_SEARCH_UPDATE } from '../../../../custom/ccCorporate'
import { ships } from '../data'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
)

export default function ShipChooser() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { search, cruiseline } = useSelector((state: ReduxState) => (state?.ico || {}) as CCState)
  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: CC_CORPORATE_SEARCH_UPDATE,
      payload: { ship_code: ev.target.value as string },
    })
  }
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Ship</InputLabel>
      <Select value={search ? search.ship_code : ''} onChange={handleChange}>
        {ships[cruiseline].map((ship: string, id: number) => (
          <MenuItem key={id} value={ship}>
            {ship}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
