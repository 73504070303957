import format from 'date-fns/format'
import React from 'react'
import { ReduxState, useQuery, useQueryWithStore } from 'react-admin'
import { useSelector } from 'react-redux'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { CCState, CC_CORPORATE_FETCHED } from '../../../custom/ccCorporate'

export function FetchData(props: any) {
  const { cmd, payload, environment, account, cruiseline, Display } = props
  const { data, error, loading } = useQuery(
    {
      type: 'create',
      resource: `carnival-corporate/${cruiseline}/${account}/${environment}`,
      payload: {
        data: {
          environment,
          account,
          cmd,
          payload,
        },
      },
    },
    { action: CC_CORPORATE_FETCHED }
  )
  return error ? (
    <Grid item>{error.message}</Grid>
  ) : data && Display ? (
    <Display data={data.entities[0]} />
  ) : null
}

export default function SearchResult(props: any) {
  const { sailing, search, environment, account, cruiseline } = useSelector(
    (state: ReduxState) => (state?.ico || {}) as CCState
  )

  return (
    <>
      {search && search.ship_code && search.from && search.to ? (
        <FetchData
          cmd="CruiseSailAvailRQ"
          environment={environment}
          account={account}
          cruiseline={cruiseline}
          payload={[
            { ship_code: search.ship_code },
            format(search.from, 'yyyy-MM-dd'),
            format(search.to, 'yyyy-MM-dd'),
          ]}
        />
      ) : null}
      {sailing && sailing.sailing_id && sailing.sailing_date && sailing.rate_code ? (
        <FetchData
          cmd="CruiseCategoryAvailRQ"
          environment={environment}
          account={account}
          cruiseline={cruiseline}
          payload={[
            {
              sailing_id: sailing.sailing_id,
              sailing_date: format(sailing.sailing_date, 'yyyy-MM-dd'),
              ship_code: sailing.ship?.code,
              rate_code: sailing.rate_code,
              number_of_guests: sailing.number_of_guests,
            },
            Array(sailing.number_of_guests || 2)
              .fill(0)
              .map((_, i) => ({})),
          ]}
        />
      ) : null}
      {sailing && sailing.sailing_id && sailing.sailing_date ? (
        <FetchData
          cmd="CruiseFareAvailRQ"
          environment={environment}
          account={account}
          cruiseline={cruiseline}
          payload={[
            {
              sailing_id: sailing.sailing_id,
              sailing_date: format(sailing.sailing_date, 'yyyy-MM-dd'),
            },
            Array(sailing.number_of_guests || 2)
              .fill(0)
              .map((_, i) => ({})),
          ]}
        />
      ) : null}
      {sailing && sailing.category_code ? (
        <FetchData
          cmd="CruiseCabinAvailRQ"
          environment={environment}
          account={account}
          cruiseline={cruiseline}
          payload={[
            {
              sailing_id: sailing.sailing_id,
              sailing_date: sailing.sailing_date,
              rate_code: sailing.rate_code,
              category_code: sailing.category_code,
            },
            Array(sailing.number_of_guests || 2)
              .fill(0)
              .map((_, i) => ({})),
          ]}
        />
      ) : null}
      {sailing && sailing.category_code ? (
        <FetchData
          cmd="CruiseDiningAvailRQ"
          environment={environment}
          account={account}
          cruiseline={cruiseline}
          payload={[
            {
              sailing_id: sailing.sailing_id,
              sailing_date: sailing.sailing_date,
              rate_code: sailing.rate_code,
              category_code: sailing.category_code,
              number_of_guests: sailing.number_of_guests,
            },
          ]}
        />
      ) : null}
      {sailing && sailing.cabin_number ? (
        <FetchData
          cmd="CruiseInvoicePriceRQ"
          environment={environment}
          account={account}
          cruiseline={cruiseline}
          payload={[
            {
              sailing_id: sailing.sailing_id,
              sailing_date: sailing.sailing_date,
              rate_code: sailing.rate_code,
              category_code: sailing.category_code,
              cabin_number: sailing.cabin_number,
            },
            Array(sailing.number_of_guests || 2)
              .fill(0)
              .map((_, i) => ({})),
            [],
            { has_flight: sailing.has_flight },
          ]}
        />
      ) : null}
      {props.children}
    </>
  )
}
