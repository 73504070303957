import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import { useFormState } from 'react-final-form'

import { LinearProgress, useMutation, useRedirect, useNotify, Button } from 'react-admin'

function CreateAgenturWithBDS({ record = {} }: any) {
  const { values } = useFormState()
  const client = record?.client?.toLowerCase() || values?.client?.toLowerCase() || null
  const payload = {
    data: {
      client: {
        email: record.data.agencyemail,
        street: record.data.street,
        city: record.data.city,
        country: record.data.country,
        zipcode: record.data.zipcode,
        surname: record.data.agencyname,
        phone: record.data.phone,
        vatId: record.data.vat,
        language: 'de',
        Roles: 'Agency',
        agency: {
          Collection: record.inkasso,
        },
      },
    },
  }

  const notify = useNotify()
  const redirect = useRedirect()

  const [update, { loading: loadingUpdate }] = useMutation()
  const [create, { loading, error }] = useMutation(
    {
      type: 'create',
      resource: `${client}/bds`,
      payload,
    },
    {
      onSuccess: ({ data }: { data: any }) => {
        notify(`agency added in davinci with ${data.id}`)
        update(
          {
            type: 'update',
            resource: `${client}/onboarding`,
            payload: {
              id: record.id,
              data: {
                link: {
                  agenturnummer: data.id,
                  crm_id: record.crm_id,
                },
              },
            },
          },
          {
            onSuccess: () => {
              notify('agency linked to onboarding')
              redirect('/onboarding')
            },
          }
        )
      },
    }
  )

  return error ? (
    <>
      <ErrorIcon />
      {error.message}
    </>
  ) : loading || loadingUpdate ? (
    <LinearProgress />
  ) : client ? (
    <Button variant="outlined" label="Add Agency in Davinci" onClick={create} disabled={loading} />
  ) : (
    <>set client to add agency</>
  )
}

CreateAgenturWithBDS.defaultProps = {
  addLabel: true,
}

export default CreateAgenturWithBDS
