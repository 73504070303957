import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useDataProvider } from 'ra-core'
import React, { useEffect, useState } from 'react'

import { Loading, ReduxState, Error } from 'react-admin'
import { useSelector } from 'react-redux'
import { CCState } from '../../../../custom/ccCorporate'
import Price from './Price'

type PriceSchema = {
  id: number
  cruiseline: string
  sailing_id: string
  sailing_date: string
  ship_code: string
  account: string
  currency: string
  category_code: string
  category_description: string
  upgrade_code: string
  cabin_location_code: string
  cabin_location_description: string
  decks: string
  available: number
  guests_min: number
  guests_max: number
  guest_type_code: number
  guest_type_description: string
  rate_code: string
  status_code: string
  status_description: string
  amount: number
  tax_fee_amount: number
  guarantee_indicator: number
  past_guest_indicator: number
  product_availability_id: number
  unique_id: string
  created: string
  changed: string
  amount_3_4_5: 0
  tax_fee_amount_3_4_5: number
  status_code_3_4_5: string
  amount_single: number
  tax_fee_amount_single: number
  status_code_single: string
  is_fake: number
  deleted: number
  onboard_credit: number
  onboard_credit_type: string
  onboard_credit_guestPricing: string
  tour_code: string
  tour_start_date: string
  transportation_type: string
}

type Rate = {
  id: number
  sailing_id: string
  cruiseline: string
  account: string
  rate_code: string
  rate_code_description: string
  status_code: string
  book_date_begin: string
  book_date_end: string
  comments: string
  currency: string
  guests_min: number
  guests_max: number
  misc_charge_amount: number
  port_charge_amount: number
  unique_id: string
  product_availability_id: number
  created: string
  changed: string
  deleted: number
  ship_code: string
  tour_code: string
  tour_start_date: string
  has_flight_option: number
  onboard_credit: number
  onboard_credit_type: string
  onboard_credit_guestPricing: string
  onboard_credit_currency: string
}

export default function DisplayCache() {
  const { sailing, search, environment, account, cruiseline } = useSelector(
    (state: ReduxState) => (state?.ico || {}) as CCState
  )
  const { sailing_id, number_of_guests = 2, category_code, rate_code } = sailing
  const [price, setPrice] = useState<PriceSchema | null>()
  const [rate, setRate] = useState<Rate>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const dataProvider = useDataProvider()

  useEffect(() => {
    if (!(cruiseline && account && sailing_id && number_of_guests && category_code && rate_code)) {
      setPrice(null)
      return
    }
    dataProvider
      .getList('product-ext/rate', {
        pagination: {
          page: 1,
          perPage: 20,
        },
        sort: {
          field: 'rate_code',
          order: 'DESC',
        },
        filter: {
          cruiseline,
          account,
          sailing_id,
          rate_code,
        },
      })
      .then(({ data: [rate] }) => {
        setRate(rate as Rate)
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })
  }, [cruiseline, account, sailing_id, number_of_guests, category_code, rate_code])

  useEffect(() => {
    if (!(cruiseline && account && sailing_id && number_of_guests && category_code && rate_code))
      return
    dataProvider
      .getList('product-ext/category', {
        pagination: {
          page: 1,
          perPage: 20,
        },
        sort: {
          field: 'category_code',
          order: 'DESC',
        },
        filter: {
          cruiseline,
          account,
          sailing_id,
          guest_type_code: number_of_guests > 2 ? 4 : number_of_guests,
          category_code,
          rate_code,
        },
      })
      .then(({ data: [price] }) => {
        setPrice(price as PriceSchema)
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })
  }, [cruiseline, account, sailing_id, number_of_guests, category_code, rate_code])
  if (loading) return <Loading />
  if (error) return <Error error={error} />
  if (!price) return null

  const formatted_price = price
    ? {
        cruise:
          number_of_guests > 2
            ? price.amount * 2 + price.amount_3_4_5 * (number_of_guests - 2)
            : number_of_guests < 2
            ? price.amount_single
            : price.amount * 2,
        taxes:
          number_of_guests > 2
            ? price.tax_fee_amount * 2 + price.tax_fee_amount_3_4_5 * (number_of_guests - 2)
            : number_of_guests < 2
            ? price.tax_fee_amount_single
            : price.tax_fee_amount * 2,
        gross: 0,
        non_commissionable: number_of_guests * (rate?.port_charge_amount || 0),
      }
    : null

  return (
    <>
      <Typography variant="h4">Cache</Typography>
      {formatted_price ? (
        <Price
          price={{ ...formatted_price, gross: formatted_price.cruise + formatted_price.taxes }}
        />
      ) : null}
    </>
  )
}
