import React, { ChangeEvent, MouseEventHandler } from 'react'

import {
  List,
  Datagrid,
  EditButton,
  TextField,
  ArrayField,
  SingleFieldList,
  Filter,
  SelectInput,
  ChipField,
} from 'react-admin'

function ListFilter(props: any) {
  return (
    <Filter {...props}>
      <SelectInput
        label="Mandant"
        source="mandant"
        choices={[
          { id: 'DE', name: 'DE' },
          { id: 'AT', name: 'AT' },
          { id: 'CH', name: 'CH' },
        ]}
      />
    </Filter>
  )
}

function PartnerAccountChip({ record, ...props }: { record: any; props: any }) {
  return record ? (
    <ChipField
      color={record.environment == 'PROD' ? 'primary' : 'secondary'}
      record={{
        id: record.id,
        value: `${record.cruiseline} ${record.account} ${record.currency_code}`,
      }}
      source="value"
    />
  ) : null
}

function DefaultAccountField({ record }: { record: any }) {
  const default_agency = (agencies: any[]) =>
    agencies
      .filter((agency: any) => agency.partner_is_default)
      .reduce((agency: string, current: any) => {
        agency = `${current.agenturnummer} / ${current.data.agencyemail}`
        return agency
      }, '')
  return <span>{default_agency(record.onboardings || [])} </span>
}

export default function PartnerList(props: any) {
  return (
    <List {...props} filters={<ListFilter />} sort={{ field: 'name', order: 'ASC' }} perPage={25}>
      <Datagrid>
        <TextField source="name" />
        <DefaultAccountField label="Default Ageny / Email" {...props} />
        <TextField source="mandant" />
        <ArrayField source="accounts">
          <SingleFieldList>
            <PartnerAccountChip {...props} />
          </SingleFieldList>
        </ArrayField>
        <EditButton />
      </Datagrid>
    </List>
  )
}
