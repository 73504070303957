export const allowed_countries = {
  de: {
    pcl: [
      'de',
      'at',
      'se',
      'fi',
      'dk',
      'no',
      'is',
      'lt',
      'lv',
      'ee',
      'fr',
      'ma',
      'tn',
      'sk',
      'cz',
      'mc',
      'lu',
      'hu',
      'si',
      'li',
    ],
    ccl: [
      'de',
      'at',
      'se',
      'fi',
      'dk',
      'no',
      'is',
      'lt',
      'lv',
      'ee',
      'fr',
      'ma',
      'tn',
      'cz',
      'pl',
      'sk',
      'si',
      'hu',
      'bg',
      'ro',
    ],
    poc: ['de', 'at', 'cz', 'sk'],
    cun: ['de', 'at', 'se', 'fi', 'dk', 'no', 'is', 'fr', 'cz', 'sk'],
    hal: ['de'],
    sbn: ['de'],
  },
  ch: {
    pcl: ['ch'],
    ccl: ['ch'],
    poc: ['ch'],
    cun: ['ch'],
    hal: ['ch'],
    sbn: ['ch'],
  },
}

export const vertrieb_options = (agency_id: number) =>
  agency_id != 1
    ? {
        SSP: 'Schnittstellenpartner',
        RON: 'Reisebüro online',
        ROF: 'Reisebüro offline',
        GRP: 'Gruppenbuchung',
        RVA: 'Veranstalter',
      }
    : {
        EOL: 'Endkunde online',
        EOF: 'Endkunde offline',
      }

export const ships = {
  pcl: [
    'MJ',
    'EP',
    'NP',
    'GP',
    'RP',
    'DI',
    'CO',
    'IP',
    'KP',
    'CB',
    'RU',
    'SA',
    'YP',
    'AP',
    'PA',
    'EX',
    'TP',
    'SP',
    'XP',
  ],
  ccl: [
    'EC',
    'LI',
    'CQ',
    'VA',
    'PA',
    'SN',
    'LE',
    'SL',
    'SH',
    'EL',
    'DR',
    'SE',
    'FD',
    'MC',
    'MD',
    'VS',
    'PO',
    'PR',
    'SP',
    'RD',
    'HZ',
    'GL',
    'MI',
    'BR',
    'FS',
    'IS',
    'CB',
  ],
  cun: ['QM', 'QU', 'QV'],
  poc: ['AZ', 'BR', 'IA', 'AU', 'VE', 'AC'],
  hal: ['WE', 'UU', 'RN', 'VE', 'RT', 'VO', 'AM', 'AA', 'NS', 'MA', 'ED', 'KO', 'OS', 'NO', 'NA'],
  sbn: ['SV', 'SJ', 'SQ', 'SE', 'SO', 'VN'],
}
