type SyncResult = {
  id: string
  status_davinci?: string
  status_docuware?: string
  option_date?: string
}

type MandantIds = Record<string, string[]>

export default (client: any, dp: any) => {
  const addSyncData = async (ids: MandantIds, props: any): Promise<SyncResult[]> => {
    const transform =
      (extra: any) =>
      (id: string): SyncResult => {
        if (id && extra) {
          const cached = extra.find((cache: any) => cache.BookingNumber == id)
          if (cached)
            return {
              id,
              status_davinci: cached.OptionStatus || '-',
              status_docuware: cached.OptionStatusDocuware || '-',
              option_date: cached.OptionDate,
            }
          return { id, status_davinci: '-', status_docuware: '-' }
        }
        return { id, status_davinci: '-', status_docuware: '-' }
      }
    let result = [] as SyncResult[]
    for (const current of Object.entries(ids)) {
      const [mandant, ids] = current
      const filter = { id: { $in: ids }, ReloadCache: true }
      const extra = await dp.getList(`${mandant}/genvas-sync`, {
        ...props,
        filter,
      })
      const transformExtra = transform(extra)
      result = [...result, ...ids.map(transformExtra)]
    }
    return result
  }

  const getSaleForDavinciId = async (ids: string[], withData: any[]) => {
    const filter = { davinci_id: { $in: ids } }
    const extra = await dp.getList('ibe-sales', { filter })
    return extra.data
      ? withData.map((x: any) => {
          const sale = extra.data.find((sale: any) => sale.davinci_id == x.TransactionId)
          return { ...x, sale }
        })
      : withData
  }

  return {
    ...dp,
    create: (ressource: any, props: any) => {
      if (ressource == 'onboarding' && props?.data?.client) {
        return dp.create(`${props.data.client.toLowerCase()}/onboarding`, props)
      }
      if (ressource == 'pmt-token') {
        return dp.create(ressource, props).then(async (result: any) => {
          const [data] = await getSaleForDavinciId([result.data.TransactionId], [result.data])
          return { ...result, data }
        })
      }
      return dp.create(ressource, props)
    },
    update: (ressource: any, props: any) => {
      if (ressource == 'onboarding') {
        return dp.update(`${props.data?.link?.client?.toLowerCase() || 'de'}/onboarding`, props)
      }
      if (ressource == 'queue/worker') {
        return dp.update(ressource, props).then(async (response: any) => {
          const result = {
            data: {
              id: props.id,
              state: response.data[0].state[props.id],
              queue: props.data.queue,
            },
          }
          return result
        })
      }
      if (ressource == 'ibe-sales') {
        return dp.update(ressource, props).then((result: any) =>
          addSyncData({ [result.data.mandant]: [result.data.davinci_id] }, props).then(
            (synced_items: SyncResult[]) => {
              const found = synced_items.find((synced_item) => synced_item.id == props.id)
              return found
                ? {
                    data: {
                      ...result.data,
                      status_davinci: found.status_davinci,
                      status_docuware: found.status_docuware,
                      option_date: found.option_date,
                    },
                  }
                : result
            }
          )
        )
      }
      return dp.update(ressource, props)
    },
    getList: async (ressource: any, props: any) => {
      if (ressource == 'partner') {
        return dp.getManyReference(ressource, {
          ...props,
          target: '$eager',
          id: '[accounts, onboardings]',
        })
      }
      if (ressource == 'cruise-voucher') {
        return dp.getManyReference(ressource, {
          ...props,
          target: '$eager',
          id: '[tasks.amount,cancellation_cruiseline,cancellation_ico]',
        })
      }
      const result = dp.getList(ressource, props)
      if (ressource == 'pmt-token') {
        return result.then(async (result: any) => {
          const ids = result.data.map((x: any) => x.TransactionId)
          return { ...result, data: await getSaleForDavinciId(ids, result.data) }
        })
      }
      if (ressource == 'ibe-sales') {
        return result.then(async (result: any) => {
          const ids: MandantIds = result.data.reduce(
            (collect: any, x: any) => {
              collect[x.mandant].push(x.davinci_id)
              return collect
            },
            { de: [], ch: [] }
          )
          return addSyncData(ids, props).then((synced_items: SyncResult[]) => ({
            ...result,
            data: result.data.map((item: any) => {
              const found = synced_items.find((synced_item) => synced_item.id == item.davinci_id)
              return found
                ? {
                    ...item,
                    status_davinci: found.status_davinci,
                    status_docuware: found.status_docuware,
                    option_date: found.option_date,
                  }
                : item
            }),
          }))
        })
      }
      return result
    },
    getMany: (ressource: any, props: any) => {
/*       if (ressource == 'crm-contact')
        return dp.getMany(ressource, props).then((response: any) => ({
          ...response,
          data: response.data.map((contact: any) => ({
            ...contact,
            crm_id: contact.contact_id,
          })),
        }))*/
      return dp.getMany(ressource, props) 
    },
    getOne: (ressource: any, props: any) => {
      if (ressource == 'onboarding')
        return dp.getOne(ressource, {
          ...props,
          query: { $eager: '[changes,credentials,partner]' },
        })
      if (ressource == 'partner')
        return dp.getOne(ressource, {
          ...props,
          query: { $eager: '[accounts, onboardings]' },
        })
      if (ressource == 'ibe-sales')
        return dp
          .getOne(ressource, {
            ...props,
            query: { $eager: '[log]' },
          })
          .then((sale: any) =>
            addSyncData({ [sale.data.mandant]: [sale.data.davinci_id] }, props).then(
              (synced_items: SyncResult[]) => {
                const [found] = synced_items
                return found
                  ? {
                      ...sale,
                      data: {
                        ...sale.data,
                        status_davinci: found.status_davinci,
                        status_docuware: found.status_docuware,
                        option_date: found.option_date,
                      },
                    }
                  : sale
              }
            )
          )

      return dp.getOne(ressource, props)
    },
    getManyReference: (ressource: any, props: any) => {
      if (ressource == 'onboarding' && props.target == 'agenturnummer') {
        const service = client.service(ressource)
        const query: any = {}
        query[props.target] = props.id
        return service.find({ query })
      }
      return dp.getManyReference(ressource, props)
    },
  }
}
