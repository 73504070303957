import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'

import { useMutation, Toolbar } from 'react-admin'
import { omit } from 'lodash'
import { DisplayBooking, BookingProps, GENVAS_CREATE } from '../../../../../custom/ccCorporate'

export default function LoadGenvasButton(props: {
  booking: DisplayBooking & BookingProps
  onData: (arg0: any) => void
}) {
  const [check, checkState] = useMutation(
    {
      type: 'create',
      resource: `${props.booking.mandant}/genvas`,
      payload: {
        data: {
          ActionCode: 'BA',
          DisplayBooking: {
            ...omit(props.booking, ['agency', 'customer', 'booking_number']),
            customer_id: props.booking.customer?.id,
            agency_id: props.booking.agency?.id || 1,
          },
        },
      },
    },
    {
      action: GENVAS_CREATE,
    }
  )

  const [book, bookState] = useMutation(
    {
      type: 'create',
      resource: `${props.booking.mandant}/genvas`,
      payload: {
        data: {
          ActionCode: 'BA',
          DisplayBooking: {
            ...omit(props.booking, ['agency', 'customer', 'booking_number']),
            customer_id: props.booking.customer?.id,
            agency_id: props.booking.agency?.id || 1,
          },
        },
      },
    },
    {
      action: GENVAS_CREATE,
    }
  )

  useEffect(() => {
    if (checkState.data) {
      props.onData(checkState.data)
    }
  }, [checkState.data])

  if (checkState.loading || bookState.loading) {
    return <>loading...</>
  }
  return (
    <Toolbar>
      <Button variant="contained" color="secondary" disabled={checkState.loading} onClick={check}>
        check
      </Button>
      <Button variant="contained" color="primary" disabled={bookState.loading} onClick={book}>
        book
      </Button>
    </Toolbar>
  )
}
