import React, { useState, useCallback } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { useCreate, useNotify } from 'react-admin'

import { Button, TextField as MUITextField, InputAdornment } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      justifyContent: 'flex-start',
    },
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
  })
)

export type PMTTokenProps = {
  Token: string
  Amount: number
  Mandant: 'de' | 'ch'
  Id: string
  Currency: string
  CustomerNo: string
  Type: string
  TransactionId: string
  TransactionRef?: string
  onSuccess?: (data: any) => void
}

export function usePMT(props: PMTTokenProps) {
  const { onSuccess, ...args } = props
  const notify = useNotify()
  const [callApi, callApiState] = useCreate(
    'pmt-token',
    {
      ...args,
    },
    {
      onSuccess,
      onFailure: (err: Error) => notify(err.message),
    }
  )

  const handleCall = useCallback(() => {
    if (!props.Amount) notify('please add an amount')
    else callApi()
  }, [props.Amount, props.TransactionRef])

  return [handleCall, callApiState]
}

function AuthorizePanel(props: any) {
  const {
    onAuthorize,
    transaction: { TransactionRef, Type },
  } = props
  const notify = useNotify()
  const classes = useStyles()
  const [CustomerNo, setCustomerNo] = useState(props.record.CustomerNo)
  const [Amount, setAmount] = useState(props.Amount)

  const onCaptureSuccess = useCallback(
    ({ data }: any) => {
      if (!TransactionRef) {
        onAuthorize({ TransactionRef: data.TransactionRef, Type: 'Capture' })
        notify('Amount authorized to capture')
      } else {
        onAuthorize({ TransactionRef: undefined, Type: undefined })
        notify('Amount captured')
      }
    },
    [TransactionRef]
  )

  const onCancelSuccess = useCallback(
    ({ data }: any) => {
      if (!TransactionRef) {
        onAuthorize({ TransactionRef: data.TransactionRef, Type: 'Cancel' })
        notify('Amount authorized to cancel')
      } else {
        onAuthorize({ TransactionRef: undefined, Type: undefined })
        notify('Amount canceled')
      }
    },
    [TransactionRef]
  )

  const [cancel, cancelState] = usePMT({
    Token: props.record.Token,
    Mandant: props.record.Mandant,
    Amount,
    Id: props.record.Id,
    Currency: props.record.Currency,
    CustomerNo,
    TransactionId: props.record.TransactionId,
    Type: 'Cancel',
    ...(TransactionRef ? { TransactionRef } : {}),
    onSuccess: onCancelSuccess,
  })

  const [capture, captureState] = usePMT({
    Token: props.record.Token,
    Mandant: props.record.Mandant,
    Amount,
    Id: props.record.Id,
    Currency: props.record.Currency,
    CustomerNo,
    TransactionId: props.record.TransactionId,
    Type: 'Capture',
    ...(TransactionRef ? { TransactionRef } : {}),
    onSuccess: onCaptureSuccess,
  })

  const loading =
    (typeof captureState === 'object' && captureState.loading) ||
    (typeof cancelState === 'object' && cancelState.loading)

  const changeCustomerNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerNo(+event.target.value)
  }
  const changeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(+event.target.value * 100)
  }

  return (
    <div className={classes.root}>
      <MUITextField
        className={classes.textField}
        disabled={!!TransactionRef}
        label="Amount"
        value={+Amount * 0.01}
        onChange={changeAmount}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <MUITextField
        className={classes.textField}
        disabled={!!TransactionRef}
        label="CustomerNo"
        value={CustomerNo}
        onChange={changeCustomerNo}
      />
      <p>
        {Type} {TransactionRef}
      </p>

      {!Type || Type == 'Cancel' ? (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => (typeof cancel === 'function' ? cancel() : null)}
          disabled={loading}
        >
          {' '}
          {TransactionRef ? 'Cancel' : 'Authorize cancelation'}{' '}
        </Button>
      ) : null}
      {!Type || Type == 'Capture' ? (
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={() => (typeof capture === 'function' ? capture() : null)}
          disabled={loading}
        >
          {' '}
          {TransactionRef ? 'Capture' : 'Authorize capture'}{' '}
        </Button>
      ) : null}
    </div>
  )
}

export default AuthorizePanel
