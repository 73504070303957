import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'
import {
  Edit,
  SaveButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SelectArrayInput,
  SimpleFormIterator,
  TabbedForm,
  FormTab,
  Toolbar,
  FunctionField,
  useMutation,
  useNotify,
  useRedirect,
} from 'react-admin'

// import Employees from '../crm-account/CrmAccountEmployeesView'

import CreateAgenturWithBDS from './CreateAgenturWithBDS'

const transform = (data: any) => {
  const {
    //crm_id,
    agenturnummer,
    inkasso,
    gewerbeschein,
    partner_id,
    partner_is_default,
    overwrite_inkasso,
    credentials,
    data: props,
  } = data
  const [credential] = credentials
  const link = { /*crm_id,*/ agenturnummer }
  return {
    partner: partner_id ? { id: partner_id, partner_is_default } : {},
    props: { ...props, inkasso, gewerbeschein, overwrite_inkasso },
    link,
    credential,
  }
}

export type Gewerbeschein = {
  id: number
  agenturnummer: string
  thumbnail: string
  url: string
}

const transformOnboarding = (props: any) => {
  const {
    credentials = [],
    agenturnummer,
    //crm_id,
    client,
    partner_id,
    partner_is_default,
    partner,
    data,
    inkasso,
    overwrite_inkasso,
    gewerbeschein,
    can_use_null_payment,
  } = props
  return {
    link: { agenturnummer, /*crm_id,*/ client },
    partner: { id: partner_id, partner_is_default },
    credentials,
    props: { ...data, inkasso, overwrite_inkasso, gewerbeschein, can_use_null_payment },
  }
}

function AgenturDavinci(props: any) {
  return (
    <>
      <TextInput source="agenturnummer" />
      {props.record?.agenturnummer ? null : <CreateAgenturWithBDS {...props} />}
    </>
  )
}

export function GewerbescheinThumb(props: any) {
  const { record: { gewerbeschein_data = {} } = {} } = props
  const { id, agenturnummer, thumbnail, url } = gewerbeschein_data
  return thumbnail ? (
    <button type="button" onClick={() => window.open(url)}>
      <img alt="" src={thumbnail} />
      <br />
      open in Docuware
    </button>
  ) : null
}

export function OnboardingEditToolbar(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton transform={transformOnboarding} />
    </Toolbar>
  )
}

function MigrateButton(props: any) {
  const notify = useNotify()
  const redirect = useRedirect()

  const [migrate, { loading: loadingUpdate }] = useMutation(
    {
      type: 'update',
      resource: `${props.record.client}/onboarding`,
      payload: {
        id: props.record.id,
        data: {
          migrate: {
            agenturnummer: props.record.agenturnummer,
            inkasso: 'Veranstalter',
          },
        },
      },
    },
    {
      onSuccess: () => {
        notify('agency migrated')
        redirect('/onboarding')
      },
    }
  )
  return props.record.inkasso == 'Reisebüro' ? (
    loadingUpdate ? (
      <>
        <CircularProgress />
        agency is beeing migrated to DI
      </>
    ) : (
      <Button variant="outlined" onClick={migrate}>
        Migrate to DI
      </Button>
    )
  ) : null
}

export default function (props: any) {
  return (
    <Edit {...props} transform={transform}>
      <TabbedForm toolbar={<OnboardingEditToolbar />}>
        <FormTab label="Address">
          <TextInput source="data.country" />
          <TextInput source="data.agencyname" />
          <TextInput source="data.agencyemail" />
          <TextInput source="data.street" />
          <TextInput source="data.streetno" />
          <TextInput source="data.zipcode" />
          <TextInput source="data.city" />
          <TextInput source="data.phone" />
          <TextInput source="data.fax" />
          <TextInput source="data.website" />
          <TextInput source="data.vatId" />
        </FormTab>
        <FormTab label="Linkeage">
          {/* <TextInput source="crm_id" /> */}
          <ReferenceInput label="API-Partner" source="partner_id" reference="partner">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput source="partner_is_default" />
          <SelectInput
            source="client"
            choices={[
              { id: 'DE', name: 'DE' },
              { id: 'AT', name: 'AT' },
              { id: 'CH', name: 'CH' },
            ]}
          />
          <MigrateButton source="inkasso" />
          <BooleanInput source="overwrite_inkasso" />
          <AgenturDavinci {...props} />
          <BooleanInput source="gewerbeschein" />
          <BooleanInput label="Keyaccount" source="can_use_null_payment" />
          <FunctionField {...props} source="gewerbeschein_data" render={GewerbescheinThumb} />
        </FormTab>
        <FormTab label="Credentials">
          <ArrayInput source="credentials">
            <SimpleFormIterator>
              <TextInput label="firstname" source="firstname" />
              <TextInput label="lastname" source="lastname" />
              <TextInput label="email" source="email" />
              <TextInput label="login" source="login" />
              <TextInput label="password" source="password" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
{/*         <FormTab label="Employees">
          <Employees {...props} />
          <ArrayInput source="crm_employees">
            <SimpleFormIterator>
              <TextInput label="firstname" source="first_name" />
              <TextInput label="lastname" source="last_name" />
              <TextInput label="email" source="email" />
              <SelectArrayInput
                label="newsletter"
                source="newsletter"
                choices={[
                  { id: 'CCL', name: 'Carnival' },
                  { id: 'PCL', name: 'Princess' },
                  { id: 'CUN', name: 'Cunard' },
                  { id: 'POC', name: 'P&O' },
                ]}
              />
              <SelectArrayInput
                label="katalog"
                source="katalog"
                choices={[
                  { id: 'CCL', name: 'Carnival' },
                  { id: 'PCL', name: 'Princess' },
                  { id: 'CUN', name: 'Cunard' },
                  { id: 'POC', name: 'P&O' },
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab> */}
      </TabbedForm>
    </Edit>
  )
}
