import React, { ChangeEvent, MouseEventHandler } from 'react'

import {
  Datagrid,
  Edit,
  Error,
  SaveButton,
  TextField,
  TextInput,
  Toolbar,
  ReferenceManyField,
  TabbedForm,
  DeleteButton,
  FormTab,
  CloneButton,
  SelectInput,
  BooleanInput,
  Pagination,
  useMutation,
  useDataProvider,
  useRefresh,
  useNotify,
} from 'react-admin'

import TextFieldMUI from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

export function SearchOnboardedAgencyList({
  search = '',
  mandant = '',
  setSearch,
  onSelectAgency,
}: {
  search: string
  mandant: string
  setSearch: any
  onSelectAgency: any
}) {
  const [mutate, { data, loading, error }] = useMutation()
  const searchForAgencies = (event: ChangeEvent<any>, input: string) => {
    setSearch(input)
    const filter = input ? { agenturnummer: { $like: `${input}%` } } : {}
    return mutate({
      type: 'getList',
      resource: 'onboarding',
      payload: {
        filter: { ...filter, client: mandant.toUpperCase() },
      },
    })
  }

  if (error) return <Error error={error} />

  return (
    <Autocomplete
      onChange={(event, value) => {
        onSelectAgency(value)
      }}
      inputValue={search}
      onInputChange={searchForAgencies}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => (option ? `${option.agenturnummer}: ${option.name}` : '')}
      options={
        data
          ? data.map(({ id, agenturnummer, client, data: { agencyname } }: any) => ({
              id,
              agenturnummer,
              name: agencyname,
              client,
            }))
          : []
      }
      loading={loading}
      renderInput={(params) => <TextFieldMUI {...params} />}
    />
  )
}

function SwitchDefaultPartner(props: any) {
  const { onboardings, record } = props
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const setDefault = async (ev: any) => {
    if (!record.hasOwnProperty('id')) return
    const agency_id = record.id
    const response = await dataProvider.update('onboarding', {
      previousData: record,
      id: agency_id,
      data: {
        client: record.client,
        partner: { id: record.partner_id, partner_is_default: ev.target.checked },
      },
    })
    const ids = onboardings
      .filter(
        ({ id, partner_is_default }: { id: string; partner_is_default: boolean }) =>
          id != agency_id && partner_is_default != false
      )
      .map((onboarding: { id: string }) => onboarding.id)
    const contact_id = response.data.contact.id
    if (ids && ids.length > 0) {
      await dataProvider.updateMany('onboarding', {
        ids,
        data: {
          partner: { id: record.partner_id, partner_is_default: false },
        },
      })
    }
    refresh()
    notify('link set to default')
  }

  return record ? (
    <Switch checked={record.partner_is_default} onChange={setDefault} name="partner_is_default" />
  ) : null
}

function LinkAgenturToPartner({ record }: { record: any }) {
  const [search, setSearch] = React.useState('')
  const [agency, setAgency] = React.useState({ id: '', client: record.mandant })
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const addAgency = (event: any, input: any) => {
    const agency_id = 'id' in agency ? agency.id : ''
    setAgency({ id: '', client: record.mandant })
    return dataProvider
      .update('onboarding', {
        previousData: record,
        id: agency_id,
        data: {
          link: { client: agency.client },
          partner: { id: record.id, partner_is_default: false },
        },
      })
      .then((response: any) => {
        refresh()
        notify('link added')
      })
  }

  return (
    <>
      <SearchOnboardedAgencyList
        search={search}
        mandant={record.mandant.toLowerCase()}
        setSearch={setSearch}
        onSelectAgency={setAgency}
      />
      <Button
        disabled={!agency || !agency.hasOwnProperty('id')}
        onClick={(event: any) => {
          addAgency(event, agency)
          setSearch('')
        }}
      >
        add
      </Button>
    </>
  )
}

function EditAgenturButton({ record }: { record: any }) {
  return record ? (
    <Button
      component={Link}
      to={{
        pathname: `/onboarding/${record.id}`,
        state: record,
      }}
    >
      {record.data.agencyname}
    </Button>
  ) : null
}

function UnlinkAgenturToPartner({ record }: { record: any }) {
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const unlinkAgency = () => {
    if (!record.hasOwnProperty('id')) return
    const agency_id = record.id
    return dataProvider
      .update('onboarding', {
        previousData: record,
        id: agency_id,
        data: {
          link: { client: record.client },
          partner: { partner_id: record.partner.id, delete: true },
        },
      })
      .then((response: any) => {
        refresh()
        notify('link removed')
      })
  }

  return (
    <Button onClick={unlinkAgency} startIcon={<DeleteIcon />}>
      Unlink
    </Button>
  )
}

const transformPartner = (data: any) => {
  const { accounts, onboardings, created_at, upodated_at, ...rest } = data
  return rest
}

export function PartnerEditToolbar(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton transform={transformPartner} />
    </Toolbar>
  )
}

function PartnerAgenturenEditList(props: any) {
  const onboardings = props.record ? props.record.onboardings : []
  return (
    <>
      <Typography variant="body2" component="div">
        Partner-Unteragenturen werden in API-Requests mit partner_id übergeben
      </Typography>
      <LinkAgenturToPartner {...props} />
      <ReferenceManyField
        {...props}
        label="Agenturen"
        target="partner_id"
        reference="onboarding"
        perPage="20"
        pagination={<Pagination />}
      >
        <Datagrid>
          <SwitchDefaultPartner onboardings={onboardings} {...props} />
          <TextField source="agenturnummer" />
          <TextField source="assigned_to" />
          <TextField source="crm_id" />
          <TextField source="state" />
          <EditAgenturButton {...props} />
          <TextField label="Email" source="data.agencyemail" />
          <UnlinkAgenturToPartner {...props} />
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}

function CreateRelatedAccountButton({ record }: { record: { id: string } }) {
  return (
    <Button
      component={Link}
      to={{
        pathname: '/partner-account/create',
        state: { record: { partner_id: record.id } },
      }}
    >
      Add an account
    </Button>
  )
}

function EditRelatedOnboarding(current: any) {
  return current && current.data ? (
    <>
      <Typography variant="body2" component="div">
        Edit default agency:{' '}
      </Typography>

      <Button
        component={Link}
        to={{
          pathname: `/onboarding/${current.id}`,
          state: { record: current },
        }}
      >
        {`${current.agenturnummer} / ${current.data.agencyemail}`}
      </Button>
    </>
  ) : null
}

function DefaultAccountField({ record }: { record: any }) {
  const default_agency = (agencies: any[]) =>
    agencies
      .filter((agency: any) => agency.partner_is_default)
      .reduce((agency: any, current: any) => current, null)
  return <EditRelatedOnboarding {...default_agency(record.onboardings || [])} />
}

export default function PartnerEdit(props: any) {
  return (
    <Edit {...props}>
      <TabbedForm toolbar={<PartnerEditToolbar />}>
        <FormTab label="Info">
          <Typography variant="body2" component="div" />
          <TextInput source="name" />
          <TextInput source="email" />
          <TextInput
            source="agenturnummer"
            helperText="Kettenkopf zur statistischen Erfassung (nur sinnvoll wenn mehrere Unteragenturen vorhanden sind)"
          />
          <BooleanInput source="bookings_cancelable" />
          <DefaultAccountField {...props} />
          <SelectInput
            source="mandant"
            choices={[
              { id: 'DE', name: 'DE' },
              { id: 'AT', name: 'AT' },
              { id: 'CH', name: 'CH' },
            ]}
          />
        </FormTab>
        <FormTab label="Accounts">
          <Typography variant="body2" component="div">
            Authentifizierung für API-Requests
          </Typography>

          <CreateRelatedAccountButton {...props} />

          <ReferenceManyField label="accounts" target="partner_id" reference="partner-account">
            <Datagrid rowClick="edit">
              <CloneButton redirect={() => `/partner/${props.id}`} />
              <TextField source="username" />
              <TextField source="password" />
              <TextField source="cruiseline" />
              <TextField source="account" />
              <TextField source="tld" />
              <TextField source="environment" />
              <TextField source="currency_code" />
              <TextField source="email" />
              <DeleteButton redirect={() => `/partner/${props.id}`} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Agenturen">
          <PartnerAgenturenEditList {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
