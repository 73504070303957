import React, { useEffect, useState } from 'react'
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import { Loading, useGetList, useMutation } from 'react-admin'

export default function (props: any) {
  const { data, ids, loading, error } = useGetList(
    'queue/worker',
    { page: 1, perPage: 10 },
    { field: 'id', order: 'DESC' }
  )
  const [state, setState] = useState({ de: false, ch: false })
  useEffect(() => {
    if (data) {
      setState({
        de: data?.genvas?.state['genvas-sync-de'] == 'ACTIVE',
        ch: data?.genvas?.state['genvas-sync-ch'] == 'ACTIVE',
      })
    }
  }, [data])
  const [mutate] = useMutation()
  const patchState = (mandant: string, workerState: boolean) =>
    mutate(
      {
        type: 'update',
        resource: 'queue/worker',
        payload: {
          id: `genvas-sync-${mandant}`,
          data: { queue: 'genvas', state: workerState ? 'ACTIVE' : 'INACTIVE' },
        },
      },
      {
        onSuccess: ({ data }) => {
          setState({ ...state, [mandant]: data.state == 'ACTIVE' })
        },
      }
    )

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <p>ERROR</p>
  }

  function handleChange(mandant: string) {
    return function (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
      patchState(mandant, checked)
    }
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={state.de}
            onChange={handleChange('de')}
            name="checkedB"
            color="primary"
          />
        }
        label="DE sync is active"
      />

      <FormControlLabel
        control={
          <Switch
            checked={state.ch}
            onChange={handleChange('ch')}
            name="checkedB"
            color="primary"
          />
        }
        label="CH sync is active"
      />
    </FormGroup>
  )
}
