import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import get from 'lodash/get'

import { LinearProgress, useQueryWithStore, Labeled, ShowButton } from 'react-admin'
import {} from '@material-ui/core'

type Onboarding = {
  id: number
  agenturnummer: number
  data: {
    agencyname: string
    agencyemail: string
  }
  partner:
    | {
        id: number
        name: string
      }
    | undefined
}

const isOnboardingList = (data: any): data is Onboarding[] =>
  Array.isArray(data) && data.length > 0 && 'id' in data[0]

function EndkundenBuchung(props: any) {
  return <p>Enkundenbuchung</p>
}

function AgencyLink({ record = {}, source = 'agency_id' }: any) {
  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: 'onboarding',
    payload: { filter: { agenturnummer: { $in: [get(record, source)] } } },
  })
  if (!loaded) return <LinearProgress />
  if (error) return <ErrorIcon />
  if (!isOnboardingList(data)) return null
  const agency = data[0]

  function RenderLink(record: any) {
    return (
      <Labeled label="Agentur">
        <ShowButton label={record.data.agencyname} basePath="/onboarding" record={{ ...record }} />
      </Labeled>
    )
  }
  function RenderLinkWithPartner(record: any) {
    return (
      <>
        <RenderLink {...record} />
        <Labeled label="Partner">
          <ShowButton
            label={record.partner.name}
            basePath="/partner"
            record={{ ...record.partner }}
          />
        </Labeled>
      </>
    )
  }
  return agency.partner && record.type == 'LegacyAPISale' ? (
    <RenderLinkWithPartner {...agency} />
  ) : (
    <RenderLink {...agency} />
  )
}

function AgencyLinkField(props: any) {
  return props.record && props.record.agency_id != 1 ? (
    <AgencyLink {...props} />
  ) : (
    <EndkundenBuchung />
  )
}

AgencyLinkField.displayName = 'AgencyLinkField'

AgencyLinkField.defaultProps = {
  addLabel: false,
}

export default AgencyLinkField
