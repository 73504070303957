import { addDays, parseISO, formatISO, isValid } from 'date-fns'
import { pick } from 'lodash'
import { Reducer as ReactReducer, useContext, useEffect } from 'react'
import { put, takeEvery } from 'redux-saga/effects'
import { showNotification } from 'react-admin'
import { ReactReduxContext } from 'react-redux'
import { Customer } from '../components/services/cc-corp/Booking/Customer'
import { Agency } from '../components/services/cc-corp/Booking/Agency'

export const CC_CORPORATE_UPDATED = 'CC/SAILING_UPDATED'
export const CC_CORPORATE_SEARCH_UPDATE = 'CC/SEARCH_UPDATE'
export const CC_CORPORATE_FETCHED = 'CC/FETCH_FROM_CORPORATE'
export const CC_CORPORATE_FETCHED_SUCCESS = 'CC/FETCH_FROM_CORPORATE_SUCCESS'
export const CC_CORPORATE_SETTING = 'CC/SETTING_UPDATED'
export const CC_CORPORATE_BOOKING_PROPS = 'CC/BOOKING_PROPS'
export const CC_CORPORATE_BOOKING_LOAD = 'CC/BOOKING_LOAD'
export const CC_CORPORATE_BOOKING_RESET = 'CC/BOOKING_RESET'
export const GENVAS_CREATE = 'GENVAS/CREATE'
export const GENVAS_CREATE_FAILURE = 'GENVAS/CREATE_FAILURE'
export const SHOW_FAILURE = 'ICO/FAILURE'
export interface UpdateSettings {
  readonly type: typeof CC_CORPORATE_SETTING
  readonly payload: Setting
}
export interface UpdateSailingAction {
  readonly type: typeof CC_CORPORATE_UPDATED
  readonly payload: Sailing
}
export interface UpdateSearchParamsAction {
  readonly type: typeof CC_CORPORATE_SEARCH_UPDATE
  readonly payload: SearchParams
}

export interface FetchFromCorpAction {
  readonly type: typeof CC_CORPORATE_FETCHED_SUCCESS
  readonly payload: any
}

export interface UpdateBookingPropsCorpAction {
  readonly type: typeof CC_CORPORATE_BOOKING_PROPS
  readonly payload: BookingProps
}
export interface LoadBookingCorpAction {
  readonly type: typeof CC_CORPORATE_BOOKING_LOAD
  readonly payload: DisplayBooking
}
export interface ResetBookingCorpAction {
  readonly type: typeof CC_CORPORATE_BOOKING_RESET
}
export interface CreateGenvas {
  readonly type: typeof GENVAS_CREATE
  readonly payload: any
}

export interface CreateGenvasFailure {
  readonly type: typeof GENVAS_CREATE_FAILURE
  readonly error: string
  readonly payload: any
}

export interface ShowFailure {
  readonly type: typeof SHOW_FAILURE
  readonly error: string
}

type Price = {
  gross: number
  guests: {
    cruise: number
    taxes: number
    gross: number
    packages: number
    non_commissionable: number
    fcc?: number
  }[]
  cruise: number
  taxes: number
  packages: number
  non_commissionable: number
  fcc?: number
}

export type CorporatePrice = {
  agent: {
    comm: number
    net: number
    gross: number
  }
  prices: Price
  non_refundable: false
}

export type Guest = {
  seq_number: string
  status: string
  title: string
  firstname: string
  middle: string
  lastname: string
  suffix: string
  nationality: string
  country_of_residence: string
  past_guest: string
  past_guest_no: string
  age: number
  birthdate: string
  address: Record<
    'D' | 'H',
    {
      street: string
      city: string
      zip: string
      state_code: string
      state_name: string
      country_code: string
      country_name: string
    }
  >
  flights: any[]
  immigration_info: any
  revision_control: {
    ratecode: boolean
    special_service: boolean
    package_status: boolean
    transportation: boolean
    name: boolean
  }
  boarding_info: {
    completed: boolean
    cruise_only: boolean
    contract: boolean
    oboard_account: boolean
    personal_info: boolean
    immigration: boolean
  }
}

export type DisplayBooking = {
  booking_number: string
  booking_date: string
  duration: string
  option_date: Date
  ship_code: string
  sailing_id: string
  sailing_date: string
  embark_port: string
  embark_port_name: string
  debark_port: string
  debark_port_name: string
  rate_code: string
  currency_code: string
  category_code: string
  cabin_number: string
  cabin_location_code: string
  cabin_location_description: string
  dining_code: string
  dining_description: string
  destination_code: string
  destination_name: string
  itinerary_code: string
  itinerary_name: string
  due_date: string
  invoice: Price
  guests: Guest[]
  synchronization_id: string
  emergency_contact: any
}

export type Service = {
  type: 'Cruise' | 'FCC' | 'Transfer' | 'Hotel'
  amount: number
  comission: number
  currency: string
}

export type BookingProps = {
  cruiseline: string
  account: string
  country: string
  language: string
  agency: Agency
  customer: Customer
  tld: string
  mandant: 'de' | 'ch'
  vertrieb: string
  option_date: Date
  services?: Service[]
  davinci: string
  ibe: string
  bookingref: string
}

export type CCState = {
  readonly error?: string
  readonly sailing: Sailing
  readonly search: SearchParams
  readonly current: SearchStore
  readonly price: CorporatePrice
  readonly display_booking?: {
    booking?: DisplayBooking
    props?: BookingProps
  }
} & Setting

type Setting = {
  environment: string
  account: string
  cruiseline: 'pcl' | 'ccl' | 'cun' | 'poc' | 'hal' | 'sbn'
}

type SearchStore = {
  sailings: { sailing_id: string; ship: Ship; sailing_date: Date }[]
  rates: string[]
  categories: string[]
  cabins: string[]
  dinings: string[]
}

type ActionTypes =
  | UpdateSettings
  | UpdateSailingAction
  | UpdateSearchParamsAction
  | FetchFromCorpAction
  | LoadBookingCorpAction
  | ResetBookingCorpAction
  | UpdateBookingPropsCorpAction
  | CreateGenvas
  | CreateGenvasFailure
  | ShowFailure

export type Sailing = {
  sailing_id?: string
  number_of_guests?: number
  sailing_date?: Date
  ship?: Ship
  rate_code?: string
  category_code?: string
  cabin_number?: string
  sitting?: string
  has_flight?: boolean
  booking_number?: string
}

export type SearchParams = {
  from?: Date
  to?: Date
  ship_code: string
}

export type Ship = {
  code: string
  name: string
}

function reviver(key: any, value: any): any {
  if (typeof value === 'string' && value.length >= 24) {
    const maybedate = parseISO(value)
    if (isValid(maybedate)) return maybedate
  }
  return value
}

function replacer(key: any, value: any): any {
  if (value instanceof Date) {
    return formatISO(value)
  }
  return value
}

export const loadState = (): CCState | void => {
  try {
    const serialState = localStorage.getItem('appState')
    if (serialState === null) {
      return undefined
    }
    const state = JSON.parse(serialState, reviver)
    return state
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: CCState) => {
  try {
    const serialState = JSON.stringify(state, replacer)
    localStorage.setItem('appState', serialState)
  } catch (err) {
    console.log(err)
  }
}

const defaultState: CCState = {
  error: undefined,
  environment: 'stage',
  account: 'de',
  cruiseline: 'pcl',
  sailing: {
    sailing_id: '',
    number_of_guests: 2,
    sailing_date: undefined,
    ship: { code: '', name: '' },
    rate_code: '',
    category_code: '',
    cabin_number: '',
    sitting: '',
    has_flight: false,
    booking_number: '',
  },
  search: {
    from: addDays(new Date(), 240),
    to: addDays(new Date(), 270),
    ship_code: '',
  },
  current: {
    sailings: [],
    rates: [],
    categories: [],
    cabins: [],
    dinings: [],
  },
  display_booking: undefined,
  price: {
    agent: {
      comm: 0,
      net: 0,
      gross: 0,
    },
    prices: {
      gross: 0,
      guests: [
        {
          cruise: 0,
          taxes: 0,
          gross: 0,
          packages: 0,
          non_commissionable: 0,
        },
        {
          cruise: 0,
          taxes: 0,
          gross: 0,
          packages: 0,
          non_commissionable: 0,
        },
      ],
      cruise: 0,
      packages: 0,
      taxes: 0,
      non_commissionable: 0,
    },
    non_refundable: false,
  },
}

export const Reducer: { ico: ReactReducer<CCState, ActionTypes> } = {
  ico: (previousErrorState = loadState() || defaultState, action: ActionTypes) => {
    const { error, ...previousState } = previousErrorState
    switch (action.type) {
      case CC_CORPORATE_SETTING: {
        const { payload } = action
        return {
          ...previousState,
          sailing: defaultState.sailing,
          price: defaultState.price,
          ...payload,
        }
      }
      case CC_CORPORATE_UPDATED:
        const { payload } = action
        const [value] = Object.keys(payload)
        let sailing = {}
        if (value == 'sailing_id') {
          const {
            current: { sailings },
          } = previousState
          sailing = pick(previousState.sailing, 'number_of_guests')
          sailing = {
            ...sailing,
            ...(sailings.find((s) => s.sailing_id == payload.sailing_id) || {
              ...payload,
            }),
          }
        }
        if (value == 'number_of_guests') {
          sailing = pick(
            previousState.sailing,
            'sailing_id',
            'ship',
            'sailing_date',
            'category_code',
            'rate_code',
            'cabin_number'
          )
        }
        if (value == 'rate_code') {
          sailing = pick(
            previousState.sailing,
            'sailing_id',
            'number_of_guests',
            'ship',
            'sailing_date'
          )
        }
        if (value == 'category_code') {
          sailing = pick(
            previousState.sailing,
            'sailing_id',
            'number_of_guests',
            'ship',
            'sailing_date',
            'rate_code'
          )
        }
        if (value == 'cabin_number') {
          sailing = pick(
            previousState.sailing,
            'sailing_id',
            'number_of_guests',
            'ship',
            'sailing_date',
            'rate_code',
            'category_code'
          )
        }
        if (value == 'sitting') {
          sailing = pick(
            previousState.sailing,
            'sailing_id',
            'number_of_guests',
            'ship',
            'sailing_date',
            'rate_code',
            'category_code',
            'cabin_number'
          )
        }
        if (value == 'has_flight') {
          sailing = pick(
            previousState.sailing,
            'sailing_id',
            'number_of_guests',
            'ship',
            'sailing_date',
            'rate_code',
            'category_code',
            'cabin_number'
          )
        }
        if (value == 'booking_number') {
          return {
            ...previousState,
            price: undefined,
            display_booking: {
              booking: undefined,
              props: previousState.display_booking?.props,
            },
            sailing: {
              ...previousState.sailing,
              booking_number: payload.booking_number,
            },
          }
        }

        return {
          ...previousState,
          price: defaultState.price,

          sailing: {
            ...defaultState.sailing,
            ...sailing,
            ...payload,
          },
        }
      case CC_CORPORATE_SEARCH_UPDATE:
        const newState = {
          ...previousState,
          sailing: {
            ...defaultState.sailing,
          },
          search: { ...(previousState.search || {}), ...action.payload },
        }
        return newState
      case CC_CORPORATE_FETCHED_SUCCESS:
        const {
          payload: {
            data: { cmd, entities },
          },
        } = action
        if (cmd == 'CruiseSailAvailRQ') {
          return {
            ...previousState,
            current: {
              ...previousState.current,
              sailings: entities.map((s: any) => ({
                sailing_id: s.sailing_id,
                sailing_date: parseISO(s.sailing_date),
                ship: {
                  code: s.ship_code,
                  name: s.ship_name,
                },
              })),
            },
          }
        }
        if (cmd == 'CruiseFareAvailRQ') {
          return {
            ...previousState,
            current: {
              ...previousState.current,
              rates: entities.map((s: any) => s.code),
            },
          }
        }
        if (cmd == 'CruiseCategoryAvailRQ') {
          return {
            ...previousState,
            current: {
              ...previousState.current,
              categories: entities.map((s: any) => s.priced_category_code),
            },
          }
        }
        if (cmd == 'CruiseCabinAvailRQ') {
          return {
            ...previousState,
            current: {
              ...previousState.current,
              cabins: entities.map((s: any) => s.cabin_number),
            },
          }
        }
        if (cmd == 'CruiseDiningAvailRQ') {
          return {
            ...previousState,
            current: {
              ...previousState.current,
              dinings: entities.map((s: any) => s.sitting),
            },
          }
        }
        if (cmd == 'CruiseInvoicePriceRQ') {
          return {
            ...previousState,
            price: entities,
          }
        }
        if (cmd == 'CruiseBookingDisplayRQ') {
          return {
            ...previousState,
            display_booking: {
              booking: entities[0],
            },
          }
        }
        return previousState
      case CC_CORPORATE_BOOKING_LOAD: {
        const { payload } = action
        return {
          ...previousState,
          display_booking: {
            booking: payload,
            props: previousState.display_booking?.props,
          },
        }
      }
      case CC_CORPORATE_BOOKING_RESET: {
        return {
          ...previousState,
          display_booking: undefined,
        }
      }
      case CC_CORPORATE_BOOKING_PROPS: {
        const { payload } = action
        return {
          ...previousState,
          display_booking: {
            booking: previousState.display_booking?.booking,
            props: { ...previousState.display_booking?.props, ...payload },
          },
        }
      }
      case SHOW_FAILURE:
      case GENVAS_CREATE_FAILURE: {
        const { error } = action
        return {
          error,
          ...previousState,
        }
      }
      default:
        return previousState
    }
  },
}

export function* Saga() {
  // yield takeEvery(CC_CORPORATE_UPDATED, function* (action) {
  //     yield put(showNotification(action.type));
  // })
}

export function History() {
  const { store } = useContext(ReactReduxContext)
  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState().ico)
    })
  }, [])
  return null
}
