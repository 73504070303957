import React, { cloneElement } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  BooleanField,
  EditButton,
  DateField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  SelectInput,
  ShowButton,
  sanitizeListRestProps,
} from 'react-admin'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextFieldMUI from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import OnboardingView from './OnboardingView'

function SelectOnboardingStates(props: any) {
  return (
    <SelectInput
      {...props}
      choices={[
        { id: 'ERROR', name: 'ERROR' },
        { id: 'OPEN', name: 'OPEN' },
        { id: 'COMPLETE', name: 'COMPLETE' },
      ]}
    />
  )
}

function ListFilter(props: any) {
  return (
    <Filter {...props}>
      <SelectInput
        label="Mandant"
        source="client"
        choices={[
          { id: 'DE', name: 'DE' },
          { id: 'AT', name: 'AT' },
          { id: 'CH', name: 'CH' },
        ]}
      />
      <TextInput label="CRM" source="crm_id" />
      <TextInput label="Agenturnummer" source="agenturnummer" />
      <SelectOnboardingStates source="state" />
    </Filter>
  )
}

function ListActions(props: any) {
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  )
}

export default function (props: any) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<ListFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick="expand" expand={<OnboardingView />}>
        <TextField source="agenturnummer" />
        <TextField source="crm_id" />
        <TextField source="data.agencyname" />
        <TextField source="state" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <TextField source="inkasso" />
        <BooleanField source="gewerbeschein" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  )
}
