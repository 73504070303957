import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import React, { ReactNode } from 'react'
import { ReduxState } from 'react-admin'
import { useSelector } from 'react-redux'
import { CCState, DisplayBooking, BookingProps } from '../../../../../custom/ccCorporate'

import QueryDavinciForm from './QueryDavinciForm'
import DisplayDavinci from './DisplayDavinci'
import DisplayCruiselineBooking from './DisplayCruiselineBooking'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

interface Props {
  children?: ReactNode
}

export default function DisplayAndSyncData(props: Props) {
  const classes = useStyles()
  const {
    error,
    cruiseline,
    display_booking: { booking = {}, props: booking_props = {} as BookingProps } = {},
  } = useSelector((state: ReduxState) => (state?.ico || {}) as CCState)
  const display_booking = {
    ...booking,
    ...booking_props,
  } as DisplayBooking & BookingProps
  return (
    <div className={classes.root}>
      {error ? <p>{error}</p> : null}
      <DisplayCruiselineBooking />
      <hr />
      <QueryDavinciForm />
      <DisplayDavinci />
      {props.children}
    </div>
  )
}
