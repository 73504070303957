import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { ReduxState } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { CCState, CC_CORPORATE_UPDATED } from '../../../../custom/ccCorporate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
)

export default function NumberOfGuestChooser() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { sailing } = useSelector((state: ReduxState) => (state?.ico || {}) as CCState)
  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: CC_CORPORATE_UPDATED,
      payload: { number_of_guests: ev.target.value as number },
    })
  }
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Number of Guests</InputLabel>
      <Select value={sailing ? sailing.number_of_guests : 2} onChange={handleChange}>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
      </Select>
    </FormControl>
  )
}
