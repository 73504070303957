import React from 'react'
import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import auth from '@feathersjs/authentication-client'
import { Admin, Resource, convertLegacyDataProvider } from 'react-admin'
import { restClient, authClient } from 'ra-data-feathers'

import OnboardingList from './components/services/onboarding/OnboardingList'
import OnboardingEdit from './components/services/onboarding/OnboardingEdit'
import OnboardingCreate from './components/services/onboarding/OnboardingCreate'
import OnboardingView from './components/services/onboarding/OnboardingView'

import SaleEdit from './components/services/sales/SaleEdit'
import SaleList from './components/services/sales/SaleList'
import SaleView from './components/services/sales/SaleView'

import PartnerList from './components/services/partner/PartnerList'
import PartnerEdit from './components/services/partner/PartnerEdit'
import PartnerCreate from './components/services/partner/PartnerCreate'

import PartnerAccountEdit from './components/services/partner-account/PartnerAccountEdit'
import PartnerAccountCreate from './components/services/partner-account/PartnerAccountCreate'

import PMTList from './components/services/pmt-token/PMTList'

import DataProvider from './DataProvider'
// import Statistics from '../statistics/Statistics'
import CCCorp from './components/services/cc-corp/Page'

import {
  History as CCCorporateHistory,
  Reducer as ccCorporateReducer,
  Saga as ccCorporateSaga,
} from './custom/ccCorporate'
import customRoutes from './custom/Routes'

import Layout from './layout/Layout'
import CruiseVoucherList from './components/services/cruise-voucher/CruiseVoucherList'
import CruiseVoucherCreate from './components/services/cruise-voucher/CruiseVoucherCreate'

function _win(name: string): string {
  return name in (window as any) ? (window as any)[name] : undefined
}

const CONFIG = {
  url: _win('APP_API_URL') || process.env.REACT_APP_API_URL,
  version: _win('APP_API_VERSION') || process.env.REACT_APP_API_VERSION,
}

const feathersClient = feathers()
  .configure(
    rest(CONFIG.url).fetch(window.fetch.bind(window), {
      headers: {
        'x-api-version': CONFIG.version,
      },
    })
  )
  .configure(
    auth({
      path: '/../authentication',
    })
  )

const options = {
  storageKey: 'feathers-jwt',
  authenticate: {
    strategy: 'ldap',
  },
  permissionsKey: 'permissions',
  permissionsField: 'units',
  passwordField: 'password',
  usernameField: 'username',
}

function AdminPage(props: any) {
  return (
    <Admin
      {...props}
      title="Admin"
      disableTelemetry
      layout={Layout}
      customRoutes={customRoutes}
      customSagas={[ccCorporateSaga]}
      customReducers={ccCorporateReducer}
      dataProvider={DataProvider(
        feathersClient,
        convertLegacyDataProvider(
          restClient(feathersClient, {
            usePatch: true,
            customQueryOperators: ['$like'],
          })
        )
      )}
      authProvider={authClient(feathersClient, options)}
    >
      <CCCorporateHistory />
      <Resource
        name="partner"
        options={{ label: 'API-Partner' }}
        create={PartnerCreate}
        list={PartnerList}
        edit={PartnerEdit}
      />

      <Resource name="partner-account" create={PartnerAccountCreate} edit={PartnerAccountEdit} />

      <Resource
        name="onboarding"
        options={{ label: 'Onboarding' }}
        list={OnboardingList}
        edit={OnboardingEdit}
        show={OnboardingView}
        create={OnboardingCreate}
      />

      <Resource
        name="ibe-sales"
        options={{ label: 'Sales' }}
        list={SaleList}
        edit={SaleEdit}
        show={SaleView}
      />

      <Resource name="pmt-token" options={{ label: 'PMT-Token' }} list={PMTList} />

      <Resource
        name="cruise-voucher"
        options={{ label: 'FCC' }}
        list={CruiseVoucherList}
        create={CruiseVoucherCreate}
      />

      <Resource name="cc-corporate" options={{ label: 'Cruises' }} list={CCCorp} />

      <Resource name="bds" />
      <Resource name="genvas" />
      <Resource name="product-ext/rate" />
      <Resource name="product-ext/category" />
      <Resource name="genvas-sync" />
      <Resource name="queue/worker" />

      {/* <Resource name="graphql" options={{ label: 'Statistics' }} list={Statistics} /> */}
    </Admin>
  )
}

export default AdminPage
