import * as React from 'react'
import {
  Box,
  Typography,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { Button, ReduxState } from 'react-admin'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { CCState } from '../../../../../custom/ccCorporate'
import PriceDisplay from '../../Display/Price'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    booking: {
      display: 'flex',
      flexDirection: 'row',
    },
    column: {
      display: 'flex',
    },
  })
)

export default function DisplayData() {
  const classes = useStyles()
  const [showDetail, setShowDetail] = useState(true)

  const { display_booking: { booking: display_booking } = {} } = useSelector(
    (state: ReduxState) => (state?.ico || {}) as CCState
  )

  return display_booking ? (
    <Box className={classes.booking}>
      <Box className={classes.column}>
        <TableContainer>
          <TableBody>
            <TableRow>
              <TableCell>Booking number</TableCell>
              <TableCell>{display_booking.booking_number}</TableCell>
            </TableRow>
            {showDetail ? (
              <>
                <TableRow>
                  <TableCell>Cabin number</TableCell>
                  <TableCell>{display_booking.cabin_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cabin category</TableCell>
                  <TableCell>{display_booking.category_code}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Embark port</TableCell>
                  <TableCell>{display_booking.embark_port_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Debark port</TableCell>
                  <TableCell>{display_booking.debark_port_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Price</TableCell>
                  <TableCell>
                    <PriceDisplay price={display_booking.invoice} />
                  </TableCell>
                </TableRow>
              </>
            ) : null}
          </TableBody>
        </TableContainer>
      </Box>
      {display_booking.guests.map((guest, i: number) => (
        <Box key={i}>
          <TableContainer>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  {guest.firstname} {guest.lastname}
                </TableCell>
              </TableRow>
              {showDetail ? (
                <TableRow>
                  <TableCell>Price</TableCell>
                  <TableCell>
                    <PriceDisplay price={display_booking.invoice.guests[i]} />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </TableContainer>
        </Box>
      ))}
      <Box>
        {showDetail ? (
          <Button onClick={() => setShowDetail(false)}>
            <span>-</span>
          </Button>
        ) : (
          <Button onClick={() => setShowDetail(true)}>
            <span>+</span>
          </Button>
        )}
      </Box>
    </Box>
  ) : null
}
