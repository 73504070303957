import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import React from 'react'
import { ReduxState } from 'react-admin'
import { useSelector } from 'react-redux'
import { CCState, DisplayBooking, BookingProps } from '../../../../../custom/ccCorporate'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

export default function DisplayDavinci() {
  const { display_booking: { booking = {}, props: booking_props = {} as BookingProps } = {} } =
    useSelector((state: ReduxState) => (state?.ico || {}) as CCState)
  const display_booking = {
    ...booking,
    ...booking_props,
  } as DisplayBooking & BookingProps

  return (
    <>
      {display_booking.services
        ? display_booking.services.map((service) => (
            <p>
              {service.type} {service.amount} {service.currency}
            </p>
          ))
        : null}
    </>
  )
}
