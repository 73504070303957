import React, { useEffect } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SelectInput,
  TextInput,
  ShowButton,
  Filter,
  useNotify,
  useDataProvider,
  useQueryWithStore,
  Loading,
  Error,
} from 'react-admin'
import { Button } from '@material-ui/core'
import { get, set } from 'lodash'
import guardedFormat from '../../../helpers'
import SyncControl from './SyncControl'

function SyncButton(props: any) {
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const sync = () =>
    dataProvider
      .update('ibe-sales', {
        previousData: props.record,
        id: props.record.davinci_id,
        data: {
          syncStatusToServices: {
            davinci_id: props.record.davinci_id,
            status: props.record.status_davinci,
          },
        },
      })
      .then(() => notify('synced'))
      .catch((err: Error) => notify(`error ${err.message}`))
  return <Button onClick={sync}>sync</Button>
}

function ListFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput label="Cruiseline Ref" source="ref" />
      <TextInput label="Davinci Ref" source="davinci_id" />
      <SelectInput
        label="Mandant"
        source="mandant"
        choices={[
          { id: 'DE', name: 'DE' },
          { id: 'AT', name: 'AT' },
          { id: 'CH', name: 'CH' },
        ]}
      />
      <SelectInput
        label="Cruiseline"
        source="cruiseline"
        choices={[
          { id: 'cel', name: 'Celestyal' },
          { id: 'ccl', name: 'Carnival Cruise Line' },
          { id: 'cun', name: 'Cunard' },
          { id: 'hal', name: 'Holland America' },
          { id: 'pcl', name: 'Princess Cruises' },
          { id: 'poc', name: 'P & O' },
          { id: 'sbn', name: 'Seabourn' },
        ]}
      />
      <SelectInput
        label="State"
        source="state"
        choices={[
          { id: 'CONFIRMED', name: 'Confirmed' },
          { id: 'OPEN', name: 'Open' },
          { id: 'ERROR', name: 'Error' },
        ]}
      />
    </Filter>
  )
}

export function DateFmtField(props: any) {
  const new_record: Record<string, string> = {}
  const value = get(props.record, props.source)
  set(new_record, props.source, value ? guardedFormat(value, 'dd.MM.yyyy HH:mm') : '')
  return (
    <TextField
      {...{
        ...props,
        record: {
          ...props.record,
          ...new_record,
        },
      }}
    />
  )
}

DateFmtField.defaultProps = {
  addLabel: true,
}

export default function (props: any) {
  return (
    <>
      <SyncControl />
      <List
        {...props}
        title="Sale"
        filters={<ListFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <Datagrid>
          <TextField source="state" label="IBE" />
          <TextField source="status_davinci" label="Davinci" />
          <TextField source="status_docuware" label="Docuware" />
          <DateFmtField source="option_date" label="Option Date" />
          <DateFmtField source="created_at" label="Booking Date" />
          <TextField source="cruiseline" label="Cruiseline" />
          <TextField source="channel" label="Booking Type" />
          <TextField source="davinci_id" label="Vorgang" />
          <TextField source="ref" label="Booking" sortable={false} />
          <TextField
            source="value.payload.cruise.sailingDate"
            label="Sailing Date"
            sortable={false}
          />
          <TextField
            source="value.payload.passengers[0].name"
            label="Pax 1 Name"
            sortable={false}
          />
          <SyncButton />
          <EditButton />
          <ShowButton />
        </Datagrid>
      </List>
    </>
  )
}
