import React from 'react'
import { SelectInput } from 'react-admin'

export default function TldField(props: any) {
  return (
    <SelectInput
      {...props}
      choices={[
        'at',
        'ch',
        'cz',
        'de',
        'dk',
        'fi',
        'fr',
        'is',
        'no',
        'pl',
        'ro',
        'se',
        'sl',
        'sk',
        'sv',
      ].map((id) => ({ id, name: id }))}
    />
  )
}
