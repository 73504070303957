import React, { useEffect, useState } from 'react'
import {
  FormControl,
  InputLabel,
  Button,
  TextField,
  MenuItem,
  createStyles,
  makeStyles,
  Theme,
  Box,
  Select,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { useQueryWithStore } from 'react-admin'

export type Customer = {
  id: number
  name?: string
  firstname?: string
  surname?: string
  street?: string
  city?: string
  country?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    initiale: {
      color: '#333',
    },
    customerInput: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
  })
)

function CustomerSearchLoader(props: {
  search: string
  mandant: string
  reset: boolean
  setCustomers: (arg0: Customer[] | void) => void
}) {
  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: `${props.mandant}/bds`,
    payload: { filter: { SearchText: props.search } },
  })

  useEffect(() => {
    if (Array.isArray(data)) props.setCustomers(data as Customer[])
  }, [data])

  useEffect(() => {
    if (props.reset) props.setCustomers()
  }, [props.reset])

  if (error) {
    props.setCustomers()
    return <>{error.message}</>
  }
  if (!loaded) {
    return <>loading...</>
  }
  return null
}

function CustomerSearch(props: {
  mandant: string
  reset: boolean
  onSelect: (customer: Customer | null) => void
}) {
  const classes = useStyles()
  const [selected, setSelected] = useState<Customer | null>()
  const [search_item, setSearchItem] = useState<{
    name: string
    search?: string
  }>({ name: '' })
  const [search_results, setSearchResults] = useState<Customer[] | void>()

  useEffect(() => {
    if (props.reset) {
      setSearchItem({ name: '', search: '' })
      setSelected(null)
    }
  }, [props.reset])

  return (
    <>
      {search_item.search ? (
        <CustomerSearchLoader
          reset={props.reset}
          search={search_item.search}
          mandant={props.mandant}
          setCustomers={setSearchResults}
        />
      ) : null}
      {search_results ? (
        <FormControl>
          <InputLabel shrink>Customers</InputLabel>
          <Select
            value={selected?.id}
            onChange={(ev) =>
              setSelected(search_results.find((c) => c.id == (ev.target.value as number)))
            }
          >
            {search_results.map((customer, i) => (
              <MenuItem key={i} value={customer.id}>
                <span className={classes.initiale}>{customer.id}</span> - {customer.firstname}{' '}
                {customer.surname} <br />
                {customer.street} {customer.city} {customer.country}
              </MenuItem>
            ))}
          </Select>
          {selected ? (
            <Button onClick={() => props.onSelect(selected)}>select customer</Button>
          ) : null}
        </FormControl>
      ) : (
        <FormControl>
          <InputLabel shrink>Search Customer</InputLabel>
          <TextField
            value={search_item.name}
            onChange={(ev) => setSearchItem({ name: ev.target.value })}
          />
          <Button onClick={() => setSearchItem({ ...search_item, search: search_item.name })}>
            search on davinci
          </Button>
        </FormControl>
      )}
    </>
  )
}

export function CustomerChooser(props: {
  mandant: string
  customer: Customer | null
  setCustomer: (arg0: Customer | null) => void
}) {
  const [reset, setReset] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (props.customer && reset) setReset(false)
  }, [props.customer])

  return (
    <>
      {props.customer?.id ? (
        <FormControl>
          <Box className={classes.customerInput}>
            <InputLabel shrink>Selected Customer</InputLabel>
            <p>{`${props.customer?.firstname} ${props.customer?.surname}`}</p>
            <Button
              onClick={() => {
                props.setCustomer(null)
                setReset(true)
              }}
            >
              <Delete />
            </Button>
          </Box>
        </FormControl>
      ) : (
        <CustomerSearch
          reset={reset}
          mandant={props.mandant}
          onSelect={(customer: Customer | null) => {
            props.setCustomer(customer)
          }}
        />
      )}
    </>
  )
}
