import React from 'react'
import { ReduxState } from 'react-admin'
import { useSelector, useDispatch } from 'react-redux'
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { CCState, CC_CORPORATE_SETTING } from '../../../../custom/ccCorporate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    card: {
      margin: theme.spacing(1),
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  })
)

const cruiselines = {
  pcl: {
    label: 'Princess Cruises',
    environments: ['live', 'stage'],
    accounts: ['de', 'sc', 'fi', 'ch', 'fr', 'at'],
  },
  ccl: {
    label: 'Carnival Cruise Line',
    environments: ['live', 'stage'],
    accounts: ['de', 'ch'],
  },
  cun: {
    label: 'Cunard',
    environments: ['live', 'stage'],
    accounts: ['ch', 'at'],
  },
  poc: {
    label: 'P&O',
    environments: ['live', 'stage'],
    accounts: ['de', 'ch'],
  },
  hal: {
    label: 'Holland America',
    environments: ['live', 'stage'],
    accounts: ['de'],
  },
  sbn: {
    label: 'Seabourn',
    environments: ['live', 'stage'],
    accounts: ['de'],
  },
}

export default function CruiselineChooser(props: any) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { environment, account, cruiseline } = useSelector(
    (state: ReduxState) => (state?.ico || {}) as CCState
  )

  const handleChange = (type: string) => (ev: React.ChangeEvent<{ value: unknown }>) => {
    const update = {
      [type]: ev.target.value as string,
    }
    dispatch({
      type: CC_CORPORATE_SETTING,
      payload: { environment, account, cruiseline, ...update },
    })
  }

  return (
    <div className={classes.root}>
      <FormControl>
        <InputLabel>Cruiseline</InputLabel>
        <Select value={cruiseline} onChange={handleChange('cruiseline')}>
          {Object.entries(cruiselines).map(([cruiseline, { label }], i) => (
            <MenuItem key={i} value={cruiseline}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>Environment</InputLabel>
        <Select value={environment} onChange={handleChange('environment')}>
          {Object.entries(cruiselines)
            .filter(([c]) => c == cruiseline)
            .map(([, { environments }]) =>
              environments.map((e, i) => (
                <MenuItem key={i} value={e}>
                  {e}
                </MenuItem>
              ))
            )}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>Account</InputLabel>
        <Select value={account} onChange={handleChange('account')}>
          {Object.entries(cruiselines)
            .filter(([c]) => c == cruiseline)
            .map(([, { accounts }]) =>
              accounts.map((e, i) => (
                <MenuItem key={i} value={e}>
                  {e}
                </MenuItem>
              ))
            )}
        </Select>
      </FormControl>
    </div>
  )
}
