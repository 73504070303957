import React from 'react'
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin'

import TldField from './TldField'

export default function PartnerAccountCreate(props: any) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="username" />
        <TextInput source="password" />
        <TextInput source="cruiseline" />
        <TextInput source="account" />
        <TldField source="tld" />
        <TextInput source="environment" />
        <TextInput source="currency_code" />
        <TextInput source="email" />
        <TextInput source="operator_email" />
        <ReferenceInput source="partner_id" reference="partner">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="operator_email" />
      </SimpleForm>
    </Create>
  )
}
