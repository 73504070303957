import React from 'react'
import {
  Edit,
  SimpleForm,
  TextField,
  Toolbar,
  SaveButton,
  TextInput,
  SelectInput,
} from 'react-admin'

const transformSale = (data: any) => {
  const { log, ...rest } = data
  return rest
}

export function SaleEditToolbar(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton transform={transformSale} />
    </Toolbar>
  )
}

export default function (props: any) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<SaleEditToolbar />}>
        <TextField source="created_at" label="Booking Date" />
        <TextField source="cruiseline" label="Cruiseline" />
        <TextField source="channel" label="Booking Type" />
        <TextField source="value.payload.sale.bookingNumber" label="Booking" />
        <TextField source="value.payload.cruise.sailingId" label="Sailing ID" />
        <TextField source="value.payload.cruise.sailingDate" label="Sailing Date" />
        <TextField source="value.payload.passengers[0].name" label="Pax 1 Name" />

        <TextInput source="davinci_id" label="Vorgangsnummer" />
        <TextInput source="agency_id" label="Agenturnummer" />
        <TextInput source="customer_id" label="Client ID" />
        <SelectInput
          source="state"
          choices={[
            { id: 'CANCELLED', name: 'Cancelled' },
            { id: 'CONFIRMED', name: 'Confirmed' },
            { id: 'OPEN', name: 'Open' },
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}
