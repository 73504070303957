import React, { ChangeEvent, useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem, TextField, Grid } from '@material-ui/core'
import { Guest } from '../../../../custom/ccCorporate'

export default function Participant(props: { guest: Guest; setGuest: (arg0: Guest) => void }) {
  const { guest, setGuest } = props

  const changeInput = (prop: string) => (ev: ChangeEvent<HTMLInputElement>) => {
    setGuest({ ...guest, [prop]: ev.target.value })
  }

  const changeSelect = (prop: string) => (ev: React.ChangeEvent<{ value: unknown }>) => {
    setGuest({ ...guest, [prop]: ev.target.value })
  }

  return (
    <Grid>
      <FormControl>
        <InputLabel shrink>Firstname</InputLabel>
        <TextField value={guest.firstname} onChange={changeInput('firstname')} />
      </FormControl>
      <FormControl>
        <InputLabel shrink>Lastname</InputLabel>
        <TextField value={guest.lastname} onChange={changeInput('lastname')} />
      </FormControl>
      <FormControl>
        <InputLabel shrink>Age</InputLabel>
        <TextField value={guest.age} onChange={changeInput('age')} />
      </FormControl>
      <FormControl>
        <InputLabel shrink>Salutation</InputLabel>
        <Select value={guest.title} onChange={changeSelect('title')}>
          <MenuItem value="Mr">Mr.</MenuItem>
          <MenuItem value="Mrs">Mrs.</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )
}
