import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  FormWithRedirect,
  List,
  TextField,
  TextInput,
  useNotify,
  useUpdate,
  SaveButton,
} from 'react-admin'

import { DateFmtField } from '../sales/SaleList'
import { CurrencyInput } from './CruiseVoucherCreate'

function TaskPanel(props: any) {
  return (
    <Box>
      <Box>
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell>Cancellation Cruiseline</TableCell>
              <TableCell>Cancellation ICO</TableCell>
              <TableCell>Cruiseline Booking</TableCell>
              <TableCell>Davinci Booking</TableCell>
              <TableCell>Amount booked</TableCell>
              <TableCell>created</TableCell>
              <TableCell>updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.record.tasks.map((task: any, i: number) => (
              <TableRow key={i}>
                {i == 0 ? (
                  <>
                    <TableCell rowSpan={props.record.tasks.length}>
                      {props.record.cancellation_cruiseline?.amount}{' '}
                      {props.record.cancellation_cruiseline?.currency}
                    </TableCell>
                    <TableCell rowSpan={props.record.tasks.length}>
                      {props.record.cancellation_ico?.amount}{' '}
                      {props.record.cancellation_ico?.currency}
                    </TableCell>
                  </>
                ) : null}
                <TableCell>{task.cruiseline_ref}</TableCell>
                <TableCell>{task.davinci_ref}</TableCell>
                <TableCell>
                  {task.amount.amount} {task.amount.currency}
                </TableCell>
                <TableCell>
                  <DateFmtField record={task} source="created_at" />
                </TableCell>
                <TableCell>
                  <DateFmtField record={task} source="updated_at" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Box>
    </Box>
  )
}

function CustomerNameField(props: any) {
  const record = {
    id: 'customer_last',
    name: `${props.record.customer_title} ${props.record.customer_first} ${props.record.customer_last} `,
  }
  return <TextField record={record} source="name" />
}
CustomerNameField.defaultProps = {
  addLabel: true,
}
export default function CruiseVoucherList(props: any) {
  return (
    <List {...props} title="FCC" sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid expand={<TaskPanel />}>
        <TextField source="cruiseline" />
        <TextField source="ship_code" />
        <TextField source="sailing_date" />
        <TextField source="cancel_date" />
        <CustomerNameField source="customer_last" label="Name" />
        <TextField source="assignee" />
        <DateFmtField source="created_at" />
        <DateFmtField source="updated_at" />
      </Datagrid>
    </List>
  )
}
