import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { de } from 'date-fns/locale'
import { add, parseISO } from 'date-fns'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { ReduxState } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import {
  CCState,
  DisplayBooking,
  BookingProps,
  CC_CORPORATE_BOOKING_PROPS,
  GENVAS_CREATE_FAILURE,
} from '../../../../../custom/ccCorporate'
import { AgenturChooser } from '../Agency'
import { CustomerChooser } from '../Customer'
import { allowed_countries, vertrieb_options } from '../../data'
import GenvasActions from './GenvasActions'

function parseService(services: any) {
  const service = Array.isArray(services) ? services : [services]
  return service
    .map((service) => {
      if (service.BookingCodeName == 'FCC')
        return {
          type: 'FCC',
          amount: service.SalesPrice.Gutschein,
          currency: service.SalesPrice.Currency,
        }
      if (service.Requirement == 'K')
        return {
          type: 'Cruise',
          amount:
            'Cunard brutto' in service.SalesPrice
              ? service.SalesPrice['Cunard brutto']
              : service.SalesPrice['Schiff brutto'],
          currency: service.SalesPrice.Currency,
        }
      return undefined
    })
    .filter((x) => x)
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

export default function QueryDavinciForm(props: any) {
  const classes = useStyles()

  const {
    cruiseline,
    display_booking: { booking = {}, props: booking_props = {} as BookingProps } = {},
  } = useSelector((state: ReduxState) => (state?.ico || {}) as CCState)
  const display_booking = {
    ...booking,
    ...booking_props,
  } as DisplayBooking & BookingProps

  const dispatch = useDispatch()
  const mandant = display_booking.mandant || 'de'
  const defaults = { mandant, cruiseline }

  const tlds = allowed_countries[mandant][cruiseline]
  const option_date = booking_props.option_date || add(new Date(), { days: 3 })

  return (
    <>
      <FormControl>
        <InputLabel>Mandant</InputLabel>
        <Select
          value={mandant}
          onChange={(ev) => {
            dispatch({
              type: CC_CORPORATE_BOOKING_PROPS,
              payload: { mandant: ev.target.value as typeof mandant },
            })
          }}
        >
          <MenuItem value="de">DE</MenuItem>
          <MenuItem value="ch">CH</MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>Language</InputLabel>
        <Select
          value={display_booking.language}
          onChange={(ev) => {
            dispatch({
              type: CC_CORPORATE_BOOKING_PROPS,
              payload: { ...defaults, language: ev.target.value as string },
            })
          }}
        >
          <MenuItem value="D">German</MenuItem>
          <MenuItem value="E">English</MenuItem>
        </Select>
      </FormControl>
      <AgenturChooser
        mandant={mandant}
        agency={display_booking.agency}
        setAgency={(agency) => {
          const payload = {
            mandant,
            agency,
            ...(agency.inkasso == 'Reisebüro' ? { customer: { id: agency.id } } : {}),
          }
          dispatch({
            type: CC_CORPORATE_BOOKING_PROPS,
            payload,
          })
        }}
      />
      {display_booking.customer?.id != display_booking.agency?.id ? (
        <CustomerChooser
          mandant={mandant}
          customer={display_booking.customer}
          setCustomer={(customer) => {
            const payload = { ...defaults, customer }
            dispatch({
              type: CC_CORPORATE_BOOKING_PROPS,
              payload,
            })
          }}
        />
      ) : null}
      <FormControl>
        <InputLabel>Vertrieb</InputLabel>
        <Select
          value={display_booking.vertrieb}
          onChange={(ev) => {
            dispatch({
              type: CC_CORPORATE_BOOKING_PROPS,
              payload: { ...defaults, vertrieb: ev.target.value as string },
            })
          }}
        >
          {Object.entries(vertrieb_options(display_booking.agency?.id)).map(([value, name], i) => (
            <MenuItem key={i} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>TLD</InputLabel>
        <Select
          value={display_booking.tld}
          onChange={(ev) => {
            dispatch({
              type: CC_CORPORATE_BOOKING_PROPS,
              payload: { ...defaults, tld: ev.target.value as string },
            })
          }}
        >
          {tlds.map((tld) => (
            <MenuItem value={tld}>{tld}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel shrink>Option date</InputLabel>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
          <KeyboardDateTimePicker
            value={option_date}
            format="dd.MM.yyyy HH:mm"
            onChange={(date: Date | null) => {
              dispatch({
                type: CC_CORPORATE_BOOKING_PROPS,
                payload: { date },
              })
            }}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <GenvasActions
        booking={display_booking}
        onData={(data) => {
          if (data.services || data.OptionDate) {
            const option_date = data.OptionDate ? { option_date: parseISO(data.OptionDate) } : {}
            const services = data.services ? { services: parseService(data.services) } : {}
            dispatch({
              type: CC_CORPORATE_BOOKING_PROPS,
              payload: { ...option_date, ...services },
            })
          } else if (data?.messages) {
            dispatch({
              type: GENVAS_CREATE_FAILURE,
              error: data.messages,
            })
          }
        }}
      />
    </>
  )
}
