import React from 'react'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  CardContent,
  Typography,
  Box,
} from '@material-ui/core'

import {
  ArrayInput,
  DateInput,
  DeleteButton,
  FormWithRedirect,
  sanitizeEmptyValues,
  SaveButton,
  SimpleFormIterator,
  Toolbar,
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  NumberInput,
  Labeled,
} from 'react-admin'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      margin: theme.spacing(1),
      flexDirection: 'column',
      '& .MuiFormControl-root': {
        flexGrow: 1,
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    name: {
      '& .MuiFormControl-root': {
        margin: 0,
      },
    },
    currency: {
      display: 'flex',
      '& .MuiFormControl-root': {
        width: '50%',
        minWidth: 'auto',
        margin: 0,
      },
    },
  })
)

function NameInput(props: any) {
  const classes = useStyles()
  return (
    <Labeled label={props.label}>
      <Box className={classes.name}>
        <TextInput label="Title" source={`${props.source}_title`} />
        <TextInput label="First" source={`${props.source}_first`} />
        <TextInput label="Last" source={`${props.source}_last`} />
      </Box>
    </Labeled>
  )
}
export function CurrencyInput(props: any) {
  const classes = useStyles()

  return (
    <Labeled label={props.label}>
      <Box className={classes.currency}>
        <NumberInput label="Amount" source={`${props.source}.amount`} />
        <SelectInput
          label="Currency"
          source={`${props.source}.currency`}
          choices={[
            { id: 'EUR', name: '€' },
            { id: 'CHF', name: 'CHF' },
            { id: 'USD', name: '$' },
            { id: 'DKK', name: 'DKK' },
            { id: 'SEK', name: 'SEK' },
            { id: 'NOK', name: 'NOK' },
          ]}
        />
      </Box>
    </Labeled>
  )
}

export function CruiseVoucherCreateForm(props: any) {
  const classes = useStyles()

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <form>
          <Box className={classes.root}>
            <Box>
              <SelectInput
                label="Cruiseline"
                source="cruiseline"
                choices={[
                  { id: 'pcl', name: 'Princess Cruises' },
                  { id: 'ccl', name: 'Carnival' },
                  { id: 'cun', name: 'Cunard' },
                  { id: 'poc', name: 'P&O' },
                ]}
              />
              <TextInput source="ship_code" />
              <TextInput source="sailing_date" />
              <TextInput source="cancel_date" />
              <TextInput source="assignee" />
            </Box>
            <Box>
              <NameInput label="Customer" source="customer" />
              <CurrencyInput label="Cruiseline Cancellation" source="cancellation_cruiseline" />
              <CurrencyInput label="ICO Cancellation" source="cancellation_ico" />
            </Box>
            <Box>
              <ArrayInput label="Bookings" source="tasks">
                <SimpleFormIterator>
                  <TextInput label="Cruiseline Booking" source="cruiseline_ref" />
                  <TextInput label="Davinci Booking" source="davinci_ref" />
                  <CurrencyInput label="Amount" source="amount" />
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Box>
          </Toolbar>
        </form>
      )}
    />
  )
}

export default function CruiseVoucherCreate(props: any) {
  return (
    <Create {...props}>
      <CruiseVoucherCreateForm />
    </Create>
  )
}
