import React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin'

import TldField from './TldField'

function CruiselineInput(props: any) {
  return (
    <SelectInput
      {...props}
      choices={['CCL', 'CUN', 'PCL', 'POC', 'PUC'].map((id) => ({ id, name: id }))}
    />
  )
}

function AccountInput(props: any) {
  return (
    <SelectInput
      {...props}
      choices={['at', 'ch', 'de', 'sc', 'fr', 'fi'].map((id) => ({ id, name: id }))}
    />
  )
}

function CurrencyInput(props: any) {
  return (
    <SelectInput
      {...props}
      choices={['CHF', 'EUR', 'DKK', 'USD', 'SEK', 'NOK'].map((id) => ({ id, name: id }))}
    />
  )
}

function PartnerSelectInput(props: any) {
  return (
    <ReferenceInput
      {...props}
      reference="partner"
      perPage="100"
      sort={{ field: 'name', order: 'ASC' }}
    >
      {props.children}
    </ReferenceInput>
  )
}

export function PartnerAccountCreate(props: any) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="username" />
        <TextInput source="password" />
        <CruiselineInput source="cruiseline" />
        <AccountInput source="account" />
        <TldField source="tld" />
        <TextInput source="environment" />
        <CurrencyInput source="currency_code" />
        <TextInput source="email" />
        <TextInput source="operator_email" />
        <PartnerSelectInput source="partner_id">
          <SelectInput optionText="name" />
        </PartnerSelectInput>
      </SimpleForm>
    </Create>
  )
}

const redirect = (basePath: any, id: any, data: any) => `/partner/${data.partner_id}/1`

export default function PartnerAccountEdit(props: any) {
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <TextField source="id" />
        <TextInput source="username" />
        <TextInput source="password" />
        <CruiselineInput source="cruiseline" />
        <AccountInput source="account" />
        <TldField source="tld" />
        <TextInput source="environment" />
        <CurrencyInput source="currency_code" />
        <TextInput source="email" />
        <TextInput source="operator_email" />
        <PartnerSelectInput source="partner_id">
          <SelectInput optionText="name" />
        </PartnerSelectInput>
        <DateInput source="created_at" />
        <DateInput source="updated_at" />
      </SimpleForm>
    </Edit>
  )
}
