import * as React from 'react'
import { forwardRef } from 'react'
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core/styles'

import Logo from './ico-logo.png'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
})

function CustomUserMenu(props: any) {
  return <UserMenu {...props} />
}

function CustomAppBar(props: any) {
  const classes = useStyles()
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <img alt="" title="" src={Logo} height={40} />
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default CustomAppBar
