import React, { useState } from 'react'
import {
  linkToRecord,
  List,
  Datagrid,
  SelectInput,
  TextInput,
  TextField,
  BooleanInput,
  Filter,
} from 'react-admin'
import { Link } from 'react-router-dom'
import PMTAuthorize from './PMTAuthorize'
import PMTTransactionList from './PMTTransactionList'

import { DateFmtField } from '../sales/SaleList'

function ListFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput label="Reference" source="Id" />
      <TextInput label="Id" source="id" />
      <SelectInput
        label="Mandant"
        source="Mandant"
        choices={[
          { id: 'de', name: 'DE' },
          { id: 'ch', name: 'CH' },
        ]}
      />
      <SelectInput
        label="Currency"
        source="currency"
        choices={[
          { id: 'EUR', name: 'EUR' },
          { id: 'DKK', name: 'DKK' },
          { id: 'SEK', name: 'SEK' },
          { id: 'NOK', name: 'NOK' },
          { id: 'CHF', name: 'CHF' },
          { id: 'USD', name: 'USD' },
        ]}
      />
    </Filter>
  )
}

function LinkToSale(props: any) {
  if (!props.record.sale) return null
  const linkToUser = linkToRecord('/ibe-sales', props.record.sale.id, 'show')

  return <Link to={linkToUser}>{props.record.TransactionId}</Link>
}

const calcAmount = (transactions: any) =>
  transactions
    .filter((t: any) => !t.Authorization)
    .map((t: any) => (t.Type == 'Cancel' ? -1 : 1) * t.Amount)
    .reduce((c: number, t: any) => c + t)

function TokenPanel(props: any) {
  const [transaction, setTransaction] = useState({ TransactionRef: undefined, Type: undefined })
  const setTransactionDebug = (props: any) => {
    setTransaction(props)
  }

  const Amount = calcAmount(props.record.transactions)

  return (
    <>
      <PMTAuthorize
        Amount={Math.abs(Amount)}
        {...props}
        transaction={transaction}
        onAuthorize={setTransactionDebug}
      />
      <PMTTransactionList
        Currency={props.record.Currency}
        CustomerNo={props.record.CustomerNo}
        TransactionId={props.record.TransactionId}
        Token={props.record.Token}
        rows={props.record.transactions || []}
      />
    </>
  )
}

export function AggregateAmount(props: any) {
  const Amount = calcAmount(props.record.transactions)

  return (
    <p>
      {Amount * 0.01} {props.record.Currency}
    </p>
  )
}

AggregateAmount.defaultProps = {
  addLabel: true,
}

export default function (props: any) {
  return (
    <List
      {...props}
      title="Token"
      filters={<ListFilter />}
      bulkActionButtons={false}
      filterDefaultValues={{ Authorization: false }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid expand={<TokenPanel />}>
        <TextField source="id" />
        <LinkToSale label="Reference" />
        <TextField source="Token" label="Token" />
        <TextField source="Currency" label="Currency" />
        <AggregateAmount label="Aggregated Amount" />
        <TextField source="CustomerNo" />
        <DateFmtField source="created_at" />
      </Datagrid>
    </List>
  )
}
