import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import LabelIcon from '@material-ui/icons/Label'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, DashboardMenuItem, MenuItemLink, MenuProps, ReduxState } from 'react-admin'
import {
  AccountCircle,
  Business,
  ChevronRight,
  CreditCard,
  Euro,
  EuroSymbol,
  Search,
  ShowChart,
  Waves,
} from '@material-ui/icons'
import SubMenu from './SubMenu'

type MenuName = 'menuCruises'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))

function Menu({ dense = true }: MenuProps) {
  const open = useSelector<ReduxState, boolean>((state) => state.admin.ui.sidebarOpen)
  const [state, setState] = useState({
    menuCruises: true,
  })
  const translate = useTranslate()
  const classes = useStyles()

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <div className={classes.root}>
      <MenuItemLink
        to="/partner"
        primaryText="Partner-API"
        dense={dense}
        leftIcon={<Business />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/onboarding"
        primaryText="Onboarding"
        dense={dense}
        leftIcon={<AccountCircle />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/ibe-sales"
        primaryText="Sales"
        dense={dense}
        leftIcon={<EuroSymbol />}
        sidebarIsOpen={open}
      />
      <MenuItemLink to="/cruise-voucher" primaryText="FCC" dense={dense} sidebarIsOpen={open} />
      <MenuItemLink
        to="/pmt-token"
        primaryText="CC Transactions"
        dense={dense}
        leftIcon={<CreditCard />}
        sidebarIsOpen={open}
      />
      <SubMenu
        handleToggle={() => handleToggle('menuCruises')}
        isOpen={state.menuCruises}
        name="Cruiselines"
        icon={<ChevronRight />}
        dense={dense}
      >
        <MenuItemLink
          to="/cc-corporate"
          primaryText="Search"
          dense={dense}
          leftIcon={<Search />}
          sidebarIsOpen={open}
        />
        <MenuItemLink
          to="/cc-corporate/booking"
          primaryText="Booking"
          dense={dense}
          leftIcon={<Waves />}
          sidebarIsOpen={open}
        />
      </SubMenu>
      {/* <MenuItemLink
        to="/graphql"
        primaryText="Statistics"
        dense={dense}
        sidebarIsOpen={open}
        leftIcon={<ShowChart />}
      /> */}
    </div>
  )
}

export default Menu
