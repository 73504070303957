import { Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import React from 'react'
import { ReduxState } from 'react-admin'
import { useSelector } from 'react-redux'
import { CCState } from '../../../../custom/ccCorporate'
import Price from './Price'

export default function Sailing() {
  const { sailing, price } = useSelector((state: ReduxState) => (state?.ico || {}) as CCState)
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Typography variant="h1">{sailing.sailing_id}</Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body1">Cabin: {sailing.cabin_number}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Rate: {sailing.rate_code}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Number of guests: {sailing.number_of_guests}</Typography>
          </Grid>
        </Grid>

        <Typography variant="h4" component="h2">
          {sailing.ship?.name}
        </Typography>

        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body1">
              Sailing date: {sailing.sailing_date && format(sailing.sailing_date, 'dd.MM.yyyy')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Category: {sailing.category_code}</Typography>
          </Grid>
        </Grid>
        <Price price={price?.prices} />
      </Grid>
    </Grid>
  )
}
