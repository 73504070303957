import { Grid } from '@material-ui/core'
import React from 'react'

export default function PriceDisplay(props: any) {
  const { price } = props
  return price && price.cruise > 0 ? (
    <Grid container spacing={1} direction="column">
      <Grid item>Cruise {price.cruise}</Grid>
      <Grid item>Taxes {price.taxes}</Grid>
      <Grid item>Gross {price.gross}</Grid>
      <Grid item>NCFR {price.non_commissionable}</Grid>
      <Grid item>Non Refundable {price.non_commissionable}</Grid>
      <Grid item>FCC {price.fcc}</Grid>
    </Grid>
  ) : null
}
