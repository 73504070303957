import * as React from 'react'
import { ReduxState, TextField, useMutation } from 'react-admin'
import { useSelector, useDispatch } from 'react-redux'
import { range } from 'lodash'
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core'
import { defaults } from '@feathersjs/authentication-client/lib'
import { format } from 'date-fns'
import { isNullableType } from 'graphql'
import { useEffect } from 'react'
import { allowed_countries } from '../data'
import Participant from './Participant'
import {
  DisplayBooking,
  BookingProps,
  CCState,
  CC_CORPORATE_BOOKING_PROPS,
  CC_CORPORATE_BOOKING_LOAD,
  Guest,
  CC_CORPORATE_BOOKING_RESET,
} from '../../../../custom/ccCorporate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  })
)

type BookingGuest = Guest & { sitting: string; gender: 'M' | 'F' }

export default function AddBooking() {
  const classes = useStyles()
  const {
    sailing,
    environment,
    account,
    cruiseline,
    display_booking: {
      booking = {} as DisplayBooking,
      props: booking_props = {} as BookingProps,
    } = {},
  } = useSelector((state: ReduxState) => (state?.ico || {}) as CCState)

  const [create, { loading, data, loaded, error }] = useMutation({
    type: 'create',
    resource: `carnival-corporate/${cruiseline}/${account}/${environment}`,
    payload: {
      data: {
        environment,
        account,
        cmd: 'CruiseBookRQ',
        payload: [
          {
            sailing_id: sailing.sailing_id,
            sailing_date: sailing.sailing_date ? format(sailing.sailing_date, 'yyyy-MM-dd') : null,
            ship_code: sailing.ship?.code,
            rate_code: sailing.rate_code,
            category_code: sailing.category_code,
            cabin_number: sailing.cabin_number,
            number_of_guests: booking.guests?.length,
          },
          booking.guests,
        ],
      },
    },
  })

  const dispatch = useDispatch()
  const mandant = booking_props.mandant || 'de'
  const tlds = allowed_countries[mandant][cruiseline]

  const guests: BookingGuest[] = (
    booking?.guests
      ? booking.guests
      : range(sailing?.number_of_guests || 2).map((n) => ({} as Guest))
  ).map((guest) => ({
    ...guest,
    gender: guest.title == 'Mr' ? 'M' : 'F',
    sitting: sailing.sitting || '',
  }))

  return (
    <div className={classes.root}>
      {error ? <strong>{error.message}</strong> : null}
      <FormControl>
        <InputLabel>TLD</InputLabel>
        <Select
          value={booking_props?.tld}
          onChange={(ev) => {
            dispatch({
              type: CC_CORPORATE_BOOKING_PROPS,
              payload: { tld: ev.target.value as string },
            })
          }}
        >
          {tlds.map((tld) => (
            <MenuItem value={tld}>{tld}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {sailing.number_of_guests
        ? guests.map((guest, i) => (
            <Participant
              guest={guest}
              setGuest={(changed) => {
                guests[i] = { ...guests[i], ...changed }
                dispatch({
                  type: CC_CORPORATE_BOOKING_LOAD,
                  payload: { ...booking, guests },
                })
              }}
            />
          ))
        : null}
      <Button onClick={create} disabled={loading}>
        create an option
      </Button>
    </div>
  )
}
