import React, { useEffect, useState } from 'react'
import {
  FormControl,
  InputLabel,
  Button,
  TextField,
  Box,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { Delete, Loop } from '@material-ui/icons'
import { useQueryWithStore } from 'react-admin'

export type Agency = {
  id: number
  name?: string
  inkasso?: 'Veranstalter' | 'Reisebüro'
}

export function AgencyLoader(props: {
  mandant: string
  agency_id: number
  reset: boolean
  setAgency: (arg0: Agency | null) => void
}) {
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: `${props.mandant}/bds`,
    payload: { id: props.agency_id },
  })
  useEffect(() => {
    if (data && data.agency)
      props.setAgency({
        id: data.id,
        name: data.surname,
        inkasso: data.agency?.Collection,
      })
  }, [data])
  useEffect(() => {
    if (props.reset) props.setAgency(null)
  }, [props.reset])

  if (error) {
    props.setAgency(null)
    return <>{error.message}</>
  }
  if (!loaded) {
    return <>loading...</>
  }
  return null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    agency: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
  })
)

export function AgenturChooser(props: {
  mandant: string
  agency: Agency | null
  setAgency: (arg0: Agency) => void
}) {
  const classes = useStyles()

  const [local_agency_id, setLocalAgencyId] = useState(props.agency?.id)
  const [agency_id, setAgencyId] = useState(props.agency?.id)
  const [agency, setAgency] = useState<Agency | null>()
  const [reset, setReset] = useState(false)

  useEffect(() => {
    if (agency && reset) setReset(false)
  }, [agency])

  return (
    <>
      <AgencyLoader
        mandant={props.mandant}
        agency_id={agency_id || 1}
        reset={reset}
        setAgency={(agency) => {
          setAgency(agency)
          agency && props.setAgency(agency)
        }}
      />
      {agency ? (
        <FormControl>
          <InputLabel shrink>Selected Agency</InputLabel>
          <Box className={classes.agency}>
            <p>
              {agency?.name} - {agency?.inkasso}
            </p>
            <Button onClick={() => setReset(true)}>
              <Delete />
            </Button>
          </Box>
        </FormControl>
      ) : (
        <FormControl>
          <Box className={classes.agency}>
            <InputLabel shrink>Agency ID</InputLabel>
            <TextField
              value={local_agency_id}
              onChange={(ev) => setLocalAgencyId(+ev.target.value)}
            />
            {local_agency_id != agency_id ? (
              <Button onClick={() => setAgencyId(local_agency_id)}>
                <Loop />
              </Button>
            ) : null}
          </Box>
        </FormControl>
      )}{' '}
    </>
  )
}
